import React, { Component } from "react";
import ReactDOM from "react-dom";
import MediaQuery from 'react-responsive';
import {ButtonToolbar, Button,Form, FormGroup,FormCheck, ControlLabel, FormControl,Row,Col} from 'react-bootstrap';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import config from '../../config/config'
import axios from "axios";
import OwlCarousel from 'react-owl-carousel';
import MetaTags from 'react-meta-tags';
import { Icon } from 'react-icons-kit'
import { chevronsRight } from 'react-icons-kit/feather'
import { Link, animateScroll as scroll } from "react-scroll";
import Actualities from '../components/actualities';
import Partenaire from '../components/partenaires';
class Map extends Component{
  

  constructor(props){

    super(props);
      this.loadscript = this.loadscript.bind(this);
      this.loadmap = this.loadmap.bind(this);
      this.loadDetailactivity = this.loadDetailactivity.bind(this);
      this.loadDetail = this.loadDetail.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);

      this.state = {
        selectedRegion: "Choisissez une région",
        listesville:["Yaoundé","Douala","Maroua","Meiganga","Bertoua"],
        params:{
          type: "studio",
          balcon: "peu importe",
          barriere: "peu importe",
          chambre: "1",
          douche: "1",
          etat: "moderne",
          ville: "Yaoundé",
          parking: "peu importe"},
        detail: false,

        activity:[
            {title: "Recherche et developpement dans les domaines de dépistage, d'aide au diagnostique de la surdité et des moyens pour améliorer l'audition.",
            image:"https://res.cloudinary.com/serpoma/image/upload/v1584720118/rd_recherche_developpement.png",
            name:"R_D"},
            {title: " Assistance technique et opérationnelle pour les campagnes de dépistage de la surdité.",
            image:"http://www.bendogroup.com/wp-content/uploads/2019/04/coverDegrade.png",
            name:"CAMPAGNE"},
            
        ],

        produits:[
          {title1:"L’appareil PEA/OEA Son’Or 2 en 1",
            title: "L’appareil PEA/OEA Son’Or 2 en 1 est idéal pour le dépistage et le diagnostic approfondi de la surdité.",
          image:"https://res.cloudinary.com/serpoma/image/upload/v1584717509/1-PEA_OEA_Ordinateur.png",
          name:"PEA_OEA"},
          {title1:"L’appareil OEA Son’Or",
            title:"L’appareil OEA Son’Or est idéal pour le dépistage et le diagnostic précoce de la surdité chez le nouveau-né.",
          image:"https://res.cloudinary.com/serpoma/image/upload/v1584717510/2-OEA_Tablette.png",
          name:"OEA"},
          {title1:" Prothèse de corps numérique",
            title: " Prothèse de corps numérique, indiquée pour les personnes présentant les signes de surdité légère, profonde ou sévère",
          image:"https://res.cloudinary.com/serpoma/image/upload/v1584717506/3-Proth%C3%A8se_Balafon_extrait.png",
          name:"PROTHESE"},
          
      ]

      }

    
  }


  scrollToTop = () => {
    scroll.scrollToTop();
};

  handleSubmit(event) {
    event.preventDefault();

    var requeste = '';
    let tmp = this.state.params;
  
  
    let i =0;
    Object.keys(tmp).map((d, key) => {

      if(i===0){
        i=1
        requeste = d+"="+tmp[d];
      }else{
        requeste = requeste +"&"+d+"="+tmp[d];
      }

    });


    localStorage.setItem('requete1', requeste);

    window.location = "#/all"
 
}





  handleChange(event) {
    let nom = event.target.name;
    let imagestmp = [];
    var k = 0;
   let paramstmp = this.state.params;

    
    if(nom ==="type"){

      if((event.target.value=="appartement")||(event.target.value=="maison")){

        this.setState({detail : true});

      }else{
        this.setState({detail : false});
      }


    }
      paramstmp[nom] = event.target.value;
      this.setState({params : paramstmp});

    
      event.preventDefault();

  }



    componentDidMount () {

        this.loadscript();
        this.scrollToTop();
        
    }



loadDetailactivity(rubrique){
      
     console.log(rubrique.name)
     if(rubrique.name=="R_D"){
       window.location = "#/rdlink/"+rubrique.name
     }else if(rubrique.name=="CAMPAGNE"){
      window.location = "#/campagnelink"
     }

      
    }

    loadDetail(rubrique){
      
     // console.log(rubrique.name)
      window.location = "#/products/"+rubrique.name
      
    }


    loadmap(x){

      return   <div className="searchResultsContainer">
      <h1 className="titre_partie_nos_produits">Nos produits</h1>
   
      <div className="resultContainer">
     
    {(this.state.produits===null) ? <em>Loading...</em> :this.state.produits.map((logement_)=>
    
        <div className="searchLinkContainer"  onClick={() => this.loadDetail(logement_)} >
          
        <div className="carResultContainer">
       
     
            <OwlCarousel
            className="owl-theme"
            loop
            items={1}
            margin={10}
            dots={false}
            nav
            navText = {[
              "<i class='icon-chevron-left icon-white'><</i>",
              "<i class='icon-chevron-right icon-white'>></i>"
              ]}
          >
         

               <div className="item" onClick={() => this.loadDetail(logement_)}><img  src={"https://res.cloudinary.com/lepiston/image/fetch/h_270/"+logement_.image}/></div>
          

          </OwlCarousel>
        
          <div className="title_Produit">
{/* <h2>Titre</h2> */}
  <h2>{logement_.title1}</h2> 
</div>
       
          <div className="carDescriptionBoxes">
                  <div className="carDescription">
                  <div className="carInformation">
                  <div className="iconDescription">
                  {logement_.title} 
                  </div>
                  </div>
                
                  </div>
                  <div class="btn-red button"> <Icon icon={chevronsRight} /> Découvrir</div>
                  </div>
                  </div>
                  </div>
                  

    )}

    

</div>
<div  className="accueil_pea_oea_resume">
  <div>Nos appareils de dépistage et de diagnostic de la surdité sont indiqués pour les tests d’audition chez le nouveau-né et les personnes présentant les signes de troubles auditifs. Ils sont recommandés dans les services de maternité, pédiatrique, ORL.</div>
  <p> Nous proposons des prothèses aux personnes diagnostiquées sourdes, pour leur permettre de surmonter leur handicap.</p>
</div>
<h1 className="titre_partie_nos_produits">Nos Activités</h1>

<div className="resultContainer">
     
     {(this.state.activity===null) ? <em>Loading...</em> :this.state.activity.map((logement_)=>
     
         <div className="searchLinkContainer"  onClick={() => this.loadDetailactivity(logement_)}>
           
         <div className="carResultContainer">
        
      
             <OwlCarousel
             className="owl-theme"
             loop
             items={1}
             margin={10}
             dots={false}
             nav
             navText = {[
               "<i class='icon-chevron-left icon-white'><</i>",
               "<i class='icon-chevron-right icon-white'>></i>"
               ]}
           >
          
 
                <div className="item" onClick={() => this.loadDetailactivity(logement_)}><img  src={"https://res.cloudinary.com/lepiston/image/fetch/h_270/"+logement_.image}/></div>
           
 
           </OwlCarousel>
         
           <Link
    activeClass="active"
    to="carDescriptionBoxes"
    spy={true}
    smooth={true}
    offset={-70}
    duration= {500}
>
</Link>
           <div className="carDescriptionBoxes">
                   <div className="carDescription">
                   <div className="carInformation">
                   
                   <div className="iconDescription">
                   {logement_.title} 
                   </div>
                   </div>
                 
                   </div>
                   <div class="btn-red"> <Icon icon={chevronsRight} /> En savoir plus</div>
                   </div>
                   </div>
                   </div>
                   
 
     )}
 
     
 
 </div>
<h1 className="titre_partie_nos_produits">Actualités</h1>
<div className="resultContainer">
<Actualities/>
</div>
{/* <h1 className="titre_partie_nos_produits">Nos partenaires</h1>
<div className="resultContainer">
<Partenaire/>
</div> */}
</div>
      
    }


    loadscript(){
    

    }



    render(){        
        return(
          <div className="body_page_accueil">

              <div className="item" ><img src={"https://res.cloudinary.com/lepiston/image/fetch/h_900/https://res.cloudinary.com/serpoma/image/upload/v1584722400/image_entete_bendosite.png"}/>
                    
                      <div className="carousel_contenu1" >
                                            <div className="popin-trigger">
                                                <h1 className="cover-title">Constructeur d'appareils biomédicaux</h1>
                                                
                                                  <span className="btn-blue-entete"  onClick={() => this.loadDetail(this.state.produits[0])}> <Icon icon={chevronsRight}  />  Découvrir</span>
                                                
                                              </div>
                                        </div>
              </div>
            
                   {this.loadmap("0 0 1000 2000")}


                  
                   
          </div>
        );



    }



}
  
 class Detail extends React.Component{
      
  constructor() {
    super();
 
  }
  render() {
      return <div><FormGroup >
      <ControlLabel>Nombre de chambres</ControlLabel>
      <FormControl name = "chambre" onChange={this.props.onChange}
            inputRef={ el => this.inputChambre=el }
            componentClass="select" placeholder="Chambre">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="plus">plus de 3</option>
      </FormControl>
    </FormGroup>


    <FormGroup >
      <ControlLabel>Nombre de douche</ControlLabel>
      <FormControl name = "douche" onChange={this.props.onChange}
            inputRef={ el => this.inputNberDouche=el }
            componentClass="select" placeholder="douche">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="plus">plus de 2</option>
      </FormControl>
    </FormGroup>

    </div>
  }
}
  
export default Map;