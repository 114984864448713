import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import { Login } from './Login/Login.js';
import { Signup } from './Signup/Signup.js';
import { Dashboard } from './Dashboard/Dashboard';
import Entry from "./Entry";
import ResultSearch from "./ResultSearch";
import RdlinkDataPage from "./RdlinkDataPage";
import LogementFormAdd from "./LogementFormAdd";
import ResultSearchDetail from "./ResultSearchDetail"
import CampagnelinkPage from "./CampagnelinkPage"
import Apropos from './Apropos';
import ContactUs from './ContactUs';
import R_d_app_dep from './R_D_App_dep';
import  R_d_app_dep_surd from './R_D_App_dep_surd';
import R_d_app_reg from './R_D_App_reg';
import R_d_prothese from './R_D_Prothese';
import R_d_sys_emb from './R_D_Sys_emb';
import Actualities from './actualities';



const Main = () => (
  // /rdlink/R_D
  < HashRouter>
    <Switch>
      <Route exact path="/" component={Entry} />
      <Route  exact path="/all" component={ResultSearch} />
      <Route exact path="/login" component={Login}/>
      <Route exact path ="/signup" component={Signup}/>
      <Route exact path ="/contactUs" component={ContactUs}/>
      <Route exact path ="/aPropos" component={Apropos}/>
      <Route exact path ="/campagnelink" component={CampagnelinkPage}/>
      <Route exact path ="/rdlink/:name" component={RdlinkDataPage}/>
      <Route exact path ="/addlogement" component={LogementFormAdd}/>
      <Route exact path ="/products/:name" component={ResultSearchDetail}/>
      <Route exact path ="/rdlink/R_D/app_dep" component={R_d_app_dep}/>
      <Route exact path ="/rdlink/R_D/app_dep_surd" component={R_d_app_dep_surd}/>
      <Route exact path ="/rdlink/R_D/app_reg" component={R_d_app_reg}/>
      <Route exact path ="/rdlink/R_D/prothese" component={R_d_prothese}/>
      <Route exact path ="/rdlink/R_D/systeme_embarque" component={R_d_sys_emb}/>
      <Route path='/dashboard' component={Dashboard}/>
    </Switch>
  </ HashRouter>
);

export default Main;