import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Parser from 'html-react-parser';
import ressouces from '../../ressources/ressouces';
class MediaCardBendo extends Component {
    constructor(props) {
        super(props);
        this.state = {
          }
        };

render () {
   // const classes = useStyles();
  return (
<div className='mediaCarContainer'>
<div className='mediaCarHeader'>
    <div className='date'>
        <div className="day_month">
        {/* <span>02/09</span> */}
  <span>{this.props.campagne.date}</span>
        </div>
        {/* <div className="year">
        <span>{this.props.year}</span>
        </div> */}
        
    </div>
<div className="mediaCardImage">
{/* <img className="image" src="/contemplative-reptile.jpeg"/> */}
{/* <img className="image" src={this.props.pathImage}/> */}
{this.props.campagne.title==="Prix Pitch My App Digital Africa Story"?
<img className="image" src={this.props.campagne.pathImage[0]}/>
:this.props.campagne.title==="Prix Challenge des 1000 Entrepreneurs"?<img className="image" src={this.props.campagne.pathImage[0]}/>:<div id={this.props.campagne.id} className="carousel" data-ride="carousel">
<ol className="carousel-indicators">
<li data-target={this.props.campagne.data_target} data-slide-to="0" class="active"></li>
<li data-target={this.props.campagne.data_target} data-slide-to="1"></li>
{this.props.campagne.id==="9"?
<li data-target={this.props.campagne.data_target} data-slide-to="2"></li>
:null}
{this.props.campagne.title==="Déploiement des appareils de dépistage Son'Or au Mozambique"?
<li data-target={this.props.campagne.data_target} data-slide-to="2"></li>
:null}
{this.props.campagne.title==="Déploiement des appareils de dépistage Son'Or au Mozambique"?
<li data-target={this.props.campagne.data_target} data-slide-to="3"></li>
:null}
{this.props.campagne.id==="10"?
<li data-target={this.props.campagne.data_target} data-slide-to="2"></li>
:null}
{this.props.campagne.id==="10"?
<li data-target={this.props.campagne.data_target} data-slide-to="3"></li>
:null}
{this.props.campagne.id==="10"?
<li data-target={this.props.campagne.data_target} data-slide-to="4"></li>
:null}
{this.props.campagne.id==="10"?
<li data-target={this.props.campagne.data_target} data-slide-to="5"></li>
:null}
</ol>
<div className="carousel-inner">
<div class="item active">
<img className="d-block w-100" src={this.props.campagne.pathImage[0]} alt="First slide"/>
</div>
<div class="item">
<img className="d-block w-100" src={this.props.campagne.pathImage[1]} alt="Second slide"/>
</div>
{this.props.campagne.pathImage[2]?<div class="item"> 
<img className="d-block w-100" src={this.props.campagne.pathImage[2]} alt="third slide"/>
</div>:null}
{this.props.campagne.pathImage[3]?<div class="item"> 
<img className="d-block w-100" src={this.props.campagne.pathImage[3]} alt="four slide"/>
</div>:null}
{this.props.campagne.pathImage[4]?<div class="item"> 
<img className="d-block w-100" src={this.props.campagne.pathImage[4]} alt="five slide"/>
</div>:null}
{this.props.campagne.pathImage[5]?<div class="item"> 
<img className="d-block w-100" src={this.props.campagne.pathImage[5]} alt="six slide"/>
</div>:null}
</div>
<a class="left carousel-control" href={this.props.campagne.data_target} data-slide="prev">
<span class="glyphicon glyphicon-chevron-left"></span> 
</a>
<a class="right carousel-control" href={this.props.campagne.data_target} data-slide="next">
<span class="glyphicon glyphicon-chevron-right"></span> 
</a>
</div>}

</div>

</div>
<div className="mediaCarTitle">
{/* <h2>Titre</h2> */}
  <h2>{this.props.campagne.title}</h2> 
</div>
<div className="mediaCarBody">
<p>
{Parser(this.props.campagne.body)}
{/* Keys help React identify which items have changed, are added, or are removed. Keys should be given to the elements inside the array to give the elements a stable identity: */}
</p>
</div>
<div className="mediaCarFooter">
<button class="button">
              <span class="glyphicon glyphicon-eye-open"></span> En savoir plus
            </button>
</div>
</div>

  );
}
}
export default MediaCardBendo;
