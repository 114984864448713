import React, { Component } from "react";
import {ButtonToolbar, Button,Form, FormGroup,FormCheck, ControlLabel, FormControl,Row,Col} from 'react-bootstrap';
import Header from './header'
import Footer from './footer'
import config from './../../config/config'
import axios from "axios";
import { Table } from "react-bootstrap";
import AlertDismissable from "./AlertDismissable";
class LogementFormAdd extends Component {

    constructor(props) {
        super(props);
        this.state = { 
          listesregions:["Région du centre","Région de l'extreme nord","Région de l'est","Région du littoral","Région du nord","Région du nord-ouest","Région de ouest","Région de ouest","Région du sud","Région du sud-ouest"],
          params:{
          type: "appartement",
          accessible_voiture: "non",
          balcon: "non",
          barriere: "non",
          chambre: "1",
          salon:"0",
          cuisine: "0",
          douche: "1",
          etat: "moderne",
          style: "R+0",
          gardien: "non",
          parking: "non",
          region: "Région du centre"},
          images: []
  
        };
    
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(event) {
        let nom = event.target.name;
        let imagestmp = [];
        var k = 0;
        var paramstmp = this.state.params;





       if(nom ==="images"){
        imagestmp=event.target.files;

        this.setState({images : imagestmp});
    

       }else{

        paramstmp[nom] = event.target.value;
        this.setState({params : paramstmp});

       }
 
      }

    
      handleSubmit(event) {
        event.preventDefault();
        let telopsource;
        let haveSalon = 1;
        let haveCuisine = 1;
        
        
         //console.log(this.inputImages.dataTransfer.files);
          //alert('A name was submitted: ' + this.state.value);
          const data = new FormData();

          
       for(let i = 0; i<this.state.images.length;i++){

           data.append('file', this.state.images[i]);

        }

        Object.keys(this.state.params).map((key, index) => {
          data.append(key, this.state.params[key]);
        })
        
        
         
       // data.append('body', this.state.params);
         
          console.log(this.state.images);

          axios.post( config.SERVER_ADRESSE+"logement/addlogement", data//this.state.params
                
            ).then((response)=>{
                console.log("-----debut----formandat");
                console.log(response.data);
                console.log("------fin------");
              })
              .catch(error => {
                  console.log(error);
              });

      }

      onPickColor(e){
        console.log('[onPickColor]', this.inputEl )
        this.setState({ color: this.inputEl.value });
      }

      genererform(){
      
      }
    

render()
  {
    return (

      <div>

       <Header/>
       <div className="confiez_cles_main">
       <p className ="titre_confiez_cles_main">Ajouter un logment au Système</p>
       <Form className="form_confiez_cles_main" method="post" action="/addlogement" enctype="multipart/form-data">

        <p className ="titre_confiez_cles_main"> Caratéristiques du logement </p>


        <fieldset>
        <FormGroup >
       <ControlLabel>Type*</ControlLabel>
       <FormControl  name ="type"
              inputRef={ el => this.inputType=el }
              componentClass="select" onChange={this.handleChange}>
            <option value="Appartement">Appartement</option>
            <option value="Studio">Studio</option>
            <option value="Chambre">Chambre</option>
            <option value="Maison">Maison</option>
            <option value="Boutique">Boutique</option>
            <option value="Magasin">Magasin</option>
        </FormControl>
       </FormGroup>

       <FormGroup >
        <ControlLabel>Catégorie</ControlLabel>
        <FormControl  name = "etat" onChange={this.handleChange}
              inputRef={ el => this.inputCategorie=el }
              componentClass="select" placeholder="catégorie">
            <option value="Moderne">Moderne</option>
            <option value="Simple">Simple</option>
        </FormControl>
      </FormGroup>

       <FormGroup >
        <ControlLabel>Nombre de chambres</ControlLabel>
        <FormControl name = "chambre" onChange={this.handleChange}
              inputRef={ el => this.inputChambre=el }
              componentClass="select" placeholder="Chambre">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="Plus de 3">plus de 3</option>
        </FormControl>
      </FormGroup>


      <FormGroup >
        <ControlLabel>Nombre de douche</ControlLabel>
        <FormControl name = "douche" onChange={this.handleChange}
              inputRef={ el => this.inputNberDouche=el }
              componentClass="select" placeholder="douche">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="Plus de 2">plus de 2</option>
        </FormControl>
      </FormGroup>

      <FormGroup >
        <ControlLabel>Nombre de salon</ControlLabel>
        <FormControl name = "salon" onChange={this.handleChange}
              componentClass="select" placeholder="salon">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="Plus de 2">plus de 2</option>
        </FormControl>
      </FormGroup>

      <FormGroup >
        <ControlLabel>Nombre de cuisine</ControlLabel>
        <FormControl name = "cuisine" onChange={this.handleChange}
              componentClass="select" placeholder="cuisine">
              <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="Plus de 2">plus de 2</option>
        </FormControl>
      </FormGroup>

      <FormGroup >
        <ControlLabel>Style de maison</ControlLabel>
        <FormControl name = "style" onChange={this.handleChange}
              componentClass="select" placeholder="style">
            <option value="R+0">R+0</option>
            <option value="R+1">R+1</option>
            <option value="R+2">R+2</option>
            <option value="R+3">R+3</option>
            <option value="R+4">R+4</option>
            <option value="R+5">R+5</option>
            <option value="R+6">R+6</option>
            <option value="R+7">R+7</option>
            <option value="R+8">R+8</option>
            <option value="R+9">R+9</option>
            <option value="R+10">R+10</option>
            <option value="Plus 10">plus du 10</option>
        </FormControl>
      </FormGroup>

      <FormGroup >
        <ControlLabel>Balcon</ControlLabel>
        <FormControl name = "balcon" onChange={this.handleChange}
              inputRef={ el => this.inputBalcon=el }
              componentClass="select" placeholder="Balcon">
            <option value="Non">Non</option>
            <option value="Oui">Oui</option>
        </FormControl>
      </FormGroup>

       <FormGroup >
        <ControlLabel>barrière</ControlLabel>
        <FormControl name = "barriere" onChange={this.handleChange}
              inputRef={ el => this.inputBarriere=el }
              componentClass="select" placeholder="barriere">
              <option value="Non">Non</option>
            <option value="Oui">Oui</option>
        </FormControl>
      </FormGroup>

      <FormGroup >
        <ControlLabel>Parking</ControlLabel>
        <FormControl name = "parking" onChange={this.handleChange}
              inputRef={ el => this.inputParking=el }
              componentClass="select" placeholder="parking">
              <option value="Non">Non</option>
            <option value="Oui">Oui</option>
        </FormControl>
      </FormGroup>

      <FormGroup >
        <ControlLabel>Gardien</ControlLabel>
        <FormControl name = "gardien" onChange={this.handleChange}
              inputRef={ el => this.inputGardien=el }
              componentClass="select" placeholder="gardien">
              <option value="Non">Non</option>
            <option value="Oui">Oui</option>
        </FormControl>
      </FormGroup>

      <FormGroup >
        <ControlLabel>Eau</ControlLabel>
        <FormControl type="text" name = "eau" placeholder="Camwater/forage/puits etc..." onChange={this.handleChange} inputRef={ el => this.inputEau=el }  />
      </FormGroup>

      <FormGroup >
        <ControlLabel>Loyer/mois(XAF)</ControlLabel>
        <FormControl type="text" name = "loyer" placeholder="150000" onChange={this.handleChange} inputRef={ el => this.inputLoyer=el }  />
      </FormGroup>

      <FormGroup >
        <ControlLabel>Avance loyer(nombre de mois exigé par le bailleur)</ControlLabel>
        <FormControl type="text" name = "avance_loyer" placeholder="6" onChange={this.handleChange} inputRef={ el => this.inputAvanceLoyer=el }  />
       
      </FormGroup>

      <FormGroup >
        <ControlLabel>Caution (XAF)</ControlLabel>
        <FormControl type="text" name = "caution" placeholder="60000" onChange={this.handleChange} inputRef={ el => this.inputCaution=el }  />
      </FormGroup>


      </fieldset>


      <p className ="titre_confiez_cles_main"> Position géographique du logement </p>

      <fieldset>
        <FormGroup >
        <ControlLabel>Région</ControlLabel>
        <FormControl name = "region" onChange={this.handleChange}
              inputRef={ el => this.inputRegion=el }
              componentClass="select" placeholder="Région">
             {this.state.listesregions.map((region)=>
                        <option value = {region}>{region}</option>
                       )}
        </FormControl>
      </FormGroup>


        <FormGroup >
          <ControlLabel>Ville* </ControlLabel>
          <FormControl type="text" name = "ville" placeholder="dans quelle ville cherchez-vous un logement?" onChange={this.handleChange} inputRef={ el => this.inputVille=el }  />
        </FormGroup>

        <FormGroup >
          <ControlLabel>Quartiers*</ControlLabel>
          <FormControl type="text" name = "quartier" placeholder="lister les quartiers où vous souahitez trouver un logement" onChange={this.handleChange} inputRef={ el => this.inputQuartier=el }  />
        </FormGroup>


        <FormGroup >
          <ControlLabel>Position*</ControlLabel>
          <FormControl type="text" name = "position" placeholder="" onChange={this.handleChange} inputRef={ el => this.inputposition=el }  />
        </FormGroup>

        <FormGroup >
          <ControlLabel>Distance de la route principale*</ControlLabel>
          <FormControl type="text" name = "distance_route_principale" placeholder="800m" onChange={this.handleChange} inputRef={ el => this.inputDistanceRouteP=el }  />
        </FormGroup>


        <FormGroup >
        <ControlLabel>Accesssible en voiture</ControlLabel>
        <FormControl name = "accessible_voiture" onChange={this.handleChange}
              inputRef={ el => this.inputAccesVoiture=el }
              componentClass="select" placeholder="Access voiture">
              <option value="Non">Non</option>
            <option value="Oui">Oui</option>
        </FormControl>
      </FormGroup>


      </fieldset>


      <fieldset>
      <FormGroup >
          <ControlLabel>Numéro du bailleur*</ControlLabel>
          <FormControl type="text" name = "numero_bailleurs" placeholder="673011550" onChange={this.handleChange} inputRef={ el => this.inputTelBailleur=el }  />
        </FormGroup>

        <FormGroup >
        <ControlLabel>images</ControlLabel>
          <input type="file" name="images" multiple  onChange={this.handleChange} inputRef={ el => this.inputImages=el }   />
        </FormGroup>
        </fieldset>

        <Button variant="primary" type="submit" onClick = {this.handleSubmit}  >
            Enregistrer
          </Button>

          </Form>
      </div>
      <Footer/>

      </div>

   )}
   
}

  export default LogementFormAdd;