import React, { Component } from "react";
import MediaQuery from 'react-responsive';

class Footer extends Component{



  anneeCurrent = ()=>{
    return (new Date().getFullYear());
  }
    render(){
        return(
            <footer className="_3mBNy" data-reactid="411">
            <div className="_1AsHn In37K" data-reactid="412">
            <div data-reactid="413">
              <div className="_3Qo_G" data-reactid="414">
                <div>
          
                  <div className="eSGyC">

                  </div>
                </div>
                <div className="_29yTo" data-reactid="498">
                  <div data-reactid="499">
                    Copyright © {this.anneeCurrent()} Bendo Group
                    
                  </div>
                  <div className="_1eQmv" data-reactid="502">

                  <MediaQuery minWidth={880}>
                  <div>Rejoignez la communauté sur!</div>
              </MediaQuery> 

                    <a className="_2j7FF trackable" href="https://www.facebook.com/Bendo_Innov-110489533948556/" target="_blank" rel="noopener nofollow" data-reactid="504"><span className="_1vK7W" name="facebook">
                        <svg height="32" width="32" viewBox="0 0 32 32">
                          <path d="M3.556 0h24.888A3.556 3.556 0 0 1 32 3.556v24.888A3.556 3.556 0 0 1 28.444 32H3.556A3.556 3.556 0 0 1 0 28.444V3.556A3.556 3.556 0 0 1 3.556 0zm23.11 3.556h-4.444A6.222 6.222 0 0 0 16 9.778v4.444h-3.556v5.334H16V32h5.333V19.556h5.334v-5.334h-5.334v-3.555c0-.982.796-1.778 1.778-1.778h3.556V3.556z" fill="#000"></path>
                        </svg></span></a><a className="_2j7FF trackable" href="#" target="_blank" rel="noopener nofollow" data-reactid="506"><span className="_1vK7W" name="twitter">
                        <svg height="25" width="25" viewBox="0 0 25 25">
                          <path d="M2.778 0h19.444A2.778 2.778 0 0 1 25 2.778v19.444A2.778 2.778 0 0 1 22.222 25H2.778A2.778 2.778 0 0 1 0 22.222V2.778A2.778 2.778 0 0 1 2.778 0zm18.41 6.685a6.905 6.905 0 0 1-2.022.581 3.608 3.608 0 0 0 1.545-2.005 6.994 6.994 0 0 1-2.235.885A3.431 3.431 0 0 0 15.88 5c-1.93 0-3.508 1.618-3.508 3.614 0 .287.033.565.09.826C9.537 9.288 6.933 7.848 5.2 5.666a3.646 3.646 0 0 0-.477 1.811c0 1.255.616 2.368 1.57 3a3.418 3.418 0 0 1-1.603-.422v.025c0 1.753 1.216 3.219 2.826 3.547a3.387 3.387 0 0 1-1.585.06c.443 1.423 1.733 2.485 3.286 2.51a6.892 6.892 0 0 1-4.379 1.55 6.99 6.99 0 0 1-.838-.05 9.799 9.799 0 0 0 5.406 1.626c6.474 0 10.032-5.51 10.032-10.287 0-.16 0-.312-.009-.472a7.146 7.146 0 0 0 1.759-1.879z" fill="#000"></path>
                        </svg></span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </footer>
        );
    }

}

export default Footer;