import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./../../css/searchcss.css"
import Pagination from "react-js-pagination";
import Header from './header'
import Footer from './footer'
import {ButtonToolbar, Button,Form, FormGroup,FormCheck, ControlLabel, FormControl,Row,Col} from 'react-bootstrap';
import ContactUsprintBarDetail from './ContactUsprintBardetail'
import ContactUsprintBar from './ContactUsprintBar'
import config from '../../config/config'
import ressouces from '../../ressources/ressouces'

import DistanceDiv from './DistanceDiv'
import SearchForm from './SearchForm'
import axios from "axios";
import MediaQuery from 'react-responsive';
import Modal from 'react-responsive-modal';
import { Icon } from 'react-icons-kit'

import { chevronsRight } from 'react-icons-kit/feather'
import { Link, animateScroll as scroll } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import cellEditFactory from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Type } from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';





const options = {
    items: 4,
}
class CampagnelinkPage extends React.Component {


  constructor(props) {
    super();
    this.state = {
      productName:null,
      respons: null,
      open: false,
      visitesimple: false,
      images: null,
      params:null,
      timeToScrool:null,
      data:[]

    }
    this.column=[
      {
        dataField: 'title',
        text: 'Hopitaux/Fondations',
        headerStyle: (colum, colIndex) => {
          return { width: '5', textAlign: 'center' };
        },
        formatter: (cell, row, enumObject, rowIndex)  =>{
          //console.log(row.description)
          return (
                  <div style={ {textAlign: 'center'} }>	 
                        <h4>
                        <a><strong style={ { color: '#C94C08'} }>{ cell }</strong></a>
                        </h4>
                        <p>
                        { row.description }
                        </p>

          </div>
          )
   }
        //hidden: true
        }/*,
       {
        dataField: 'description',
        text: 'Description',
        headerStyle: (colum, colIndex) => {
            return { width: '5', textAlign: 'center' };
          }
        }*/
    ];
    this.gotoLink = this.gotoLink.bind(this);
    this.loaContenue = this.loaContenue.bind(this);
  }


  
 
componentDidMount() {
  //var url = config.SERVER_ADRESSE + 'trouver-un-logement/liste-de-ma-recherche?region=Centre&type=Studio';

   this.scrollToBottom()
   this.loadData();
}
 


  componentWillMount(){

    
    this.scrollToBottom()
  }


  scrollToBottom = (x) => {

    scroll.scrollTo(x);
};


  gotoLink(){

    return  <Link
          activeClass="active"
          to="carDescriptionBoxes"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
      >
      </Link>
  }

loadData=()=>{
  let source=[];
  source.push(ressouces.ACTIVITY.CAMPAGNE.case1);
  source.push(ressouces.ACTIVITY.CAMPAGNE.case2);
  source.push(ressouces.ACTIVITY.CAMPAGNE.case3);
  source.push(ressouces.ACTIVITY.CAMPAGNE.case4);
  this.setState({
    data:source
  })
  
}
  loaContenue(){

    return   <div >
   
          <div className="homeActivitiesContainer">


                <div className="titre_activite_div_c">
                <h1 className="titre_activite">{ressouces.ACTIVITY.CAMPAGNE.title}</h1>
                </div>  


                  <MediaQuery minWidth={1034}>

                                                    <div className="campagnes_objectif">

                                    <div className="rd_generalites_c">

                                            
                                        <div className="carousel_activite_detail" >
                                          
                                                  {ressouces.ACTIVITY.CAMPAGNE.description}
                                                                    
                                                  
                                        </div>

                                        <ul>

                                        {ressouces.ACTIVITY.CAMPAGNE.description2.map((val)=>
                                          <li>{val}</li>
                                          )}

                                      </ul>

                                    </div> 



                                    <div className="campagnes_caroussel">
                                            <OwlCarousel
                                              className="owl-theme"
                                              loop
                                              items={1}
                                              margin={10}
                                              dots={true}
                                              nav
                                            
                                            >
                                          

                                          {ressouces.ACTIVITY.CAMPAGNE.imgs.map((val)=>
                                              <div className="item" ><img  src={val}/></div>
                                          )}

                                                
                                            

                                          </OwlCarousel>    

                                    </div>
                                    </div> 


                  </MediaQuery>



                  <MediaQuery maxWidth={1034}>

                      <div className="campagnes_objectif">

                      <div className="campagnes_caroussel">
                                    <OwlCarousel
                                      className="owl-theme"
                                      loop
                                      items={1}
                                      margin={10}
                                      dots={true}
                                    
                                      nav
                                    
                                    >
                                  

                                  {ressouces.ACTIVITY.CAMPAGNE.imgs.map((val)=>
                                      <div className="item" ><img  src={val}/></div>
                                  )}

                                        
                                    

                                  </OwlCarousel>    

                            </div>



                            <div className="rd_generalites_c">

                                    
                                <div className="carousel_activite_detail" >
                                  
                                          {ressouces.ACTIVITY.CAMPAGNE.description}
                                                            
                                          
                                </div>

                                <ul>

                                {ressouces.ACTIVITY.CAMPAGNE.description2.map((val)=>
                                  <li>{val}</li>
                                  )}

                              </ul>

                            </div> 

                            </div> 


                  </MediaQuery>

               



    <div className="activity_separation"></div>

                <div className="titre_activite_div">
                <h1 className="titre_activite">{ressouces.ACTIVITY.CAMPAGNE.case1.title}</h1>
                </div>  




                <div className="rd_generalites">
        
                <div className="item-activity" >
                <img src={config.CLOUDIMGS_ACTIVITY+ressouces.ACTIVITY.CAMPAGNE.case1.img1}/>

                </div>

                        
                    <div className="carousel_activite_detail" >
                      
                              {ressouces.ACTIVITY.CAMPAGNE.case1.description}
                                                
                              
                    </div>


                </div> 



     <div className="activity_separation"></div>

                <div className="titre_activite_div">
                <h1 className="titre_activite">{ressouces.ACTIVITY.CAMPAGNE.case2.title}</h1>
                </div>  


                
                <MediaQuery minWidth={1035}>
                      <div className="rd_generalites">
                    
                                            
                          <div className="carousel_activite_detail" >
                      
                              {ressouces.ACTIVITY.CAMPAGNE.case2.description}                   
                                    
                          </div>
                          

                        <div className="item-activity" >
                         <img src={config.CLOUDIMGS_ACTIVITY+ressouces.ACTIVITY.CAMPAGNE.case2.img1}/>

                      </div>

                  </div> 


              </MediaQuery>

              <MediaQuery maxWidth={1034}>
                      <div className="rd_generalites">
                      

                                <div className="item-activity" >
                                  <img src={config.CLOUDIMGS_ACTIVITY+ressouces.ACTIVITY.CAMPAGNE.case2.img1}/>

                                  </div>

                                                  
                                <div className="carousel_activite_detail" >
                                  
                                          {ressouces.ACTIVITY.CAMPAGNE.case2.description}                   
                                          
                                </div>
                                    

                          

                    </div> 


              </MediaQuery>


                   

     
      



       <div className="activity_separation"></div>

                <div className="titre_activite_div">
                <h1 className="titre_activite">{ressouces.ACTIVITY.CAMPAGNE.case3.title}</h1>
                </div>  

               <div className="rd_generalites">
                      
                      <div className="item-activity" >
                      <img src={config.CLOUDIMGS_ACTIVITY+ressouces.ACTIVITY.CAMPAGNE.case3.img1}/>

                      </div>
                            
                          <div className="carousel_activite_detail" >
                            
                                    {ressouces.ACTIVITY.CAMPAGNE.case3.description}
                                                                                
                          </div>

                    </div> 


       <div className="activity_separation"></div>
     
            <div className="titre_activite_div">
                <h1 className="titre_activite">{ressouces.ACTIVITY.CAMPAGNE.case4.title}</h1>
                </div>  


                
                      

                <MediaQuery minWidth={1035}>
                <div className="rd_generalites">
              
                                
                  <div className="carousel_activite_detail" >
                    
                            {ressouces.ACTIVITY.CAMPAGNE.case4.description}                   
                            
                  </div>
              

                    <div className="item-activity" >
                  <img src={config.CLOUDIMGS_ACTIVITY+ressouces.ACTIVITY.CAMPAGNE.case4.img1}/>

                  </div>

              </div> 


              </MediaQuery>

              <MediaQuery maxWidth={1034}>
                 <div className="rd_generalites">
              

                        <div className="item-activity" >
                      <img src={config.CLOUDIMGS_ACTIVITY+ressouces.ACTIVITY.CAMPAGNE.case4.img1}/>

                      </div>


                    <div className="carousel_activite_detail" >
                        
                                {ressouces.ACTIVITY.CAMPAGNE.case4.description}                   
                                  
                        </div>
                        

                       

                  </div> 


              </MediaQuery>



    





      <div>

    </div>

    </div>       
    </div>
  }

  render()
  {

    const { open } = this.state;

    return (
      <div>
        <div className="row x">
        <div className="col-lg-12 col-md-12">
          <h1 className="titre_activite">
          {ressouces.ACTIVITY.CAMPAGNE.title}
          </h1>
        </div>
        </div>
        <div className="row">
        <div className="col-lg-12 col-md-12 carousel_activite_detail" >
            {ressouces.ACTIVITY.CAMPAGNE.description} 
            </div>
        </div>
        <div className="row body_campagne x">
      <div className="col-lg-6 col-md-6 carousel_activite_detail">
      <ul className="fa-ul">
{ressouces.ACTIVITY.CAMPAGNE.description2.map((val)=> 
    <li>
      <span class="fa-li"><i className="fas fa-spinner fa-pulse"></i></span>{val}</li>
  )}

</ul>
      </div>
      <div id="exemple-carrousel" className="carousel slide col-lg-6 col-md-6" data-ride="carousel">
      <ol className="carousel-indicators">
    <li data-target="#exemple-carrousel" data-slide-to="0" class="active"></li>
    <li data-target="#exemple-carrousel" data-slide-to="1"></li>
  </ol>
  <div className="carousel-inner">
  <div class="item active">
  <img className="d-block w-100" src={ressouces.ACTIVITY.CAMPAGNE.imgs[0]} alt="First slide"/>
</div>
<div class="item">
  <img className="d-block w-100" src={ressouces.ACTIVITY.CAMPAGNE.imgs[1]} alt="Second slide"/>
</div>
</div>
  <a class="left carousel-control" href="#exemple-carrousel" data-slide="prev">
  <span class="glyphicon glyphicon-chevron-left"></span> 
  </a>
  <a class="right carousel-control" href="#exemple-carrousel" data-slide="next">
  <span class="glyphicon glyphicon-chevron-right"></span> 
  </a>
</div>
  </div>
  <div className="row">
<div className=" col-lg-12 col-md-12">
<BootstrapTable 
		striped
		hover
		bordered={ false }
    keyField="id"
	data={this.state.data}
	//data={ListContact}
	columns={this.column}
	pagination={ paginationFactory() }
  />
</div>
</div>           
<div className="body_campagne">

</div>
     {/* {this.loaContenue()} */}
              {/* <Footer/> */}
{/*               <div className="row">
        <Footer/>
        </div> */}
        </div> 

    )
  }
}

export default CampagnelinkPage;


{/* <li>
<FontAwesomeIcon icon={faCheckSquare} listItem />
{val}</li> */}