import React, { Component } from "react";
import config from '../../config/config'
import MainNavigation from '../components/Navigation/MainNavigation'
import MediaQuery from 'react-responsive';



class Header extends Component{


  constructor(){
    super();
   
    this.state = {
          displayMenu: false,
          iscampagne:false,
        };
   
     this.showDropdownMenu = this.showDropdownMenu.bind(this);
     this.hideDropdownMenu = this.hideDropdownMenu.bind(this);


   
   };



   showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
    document.addEventListener('click', this.hideDropdownMenu);
    });

  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }


    render(){
        return(
          <header role="banner" data-reactid="14">
       
       <div  className="header_mainterface">  
         <MediaQuery maxWidth={970}>
         
         <MainNavigation className="left_menu_bar"/>

         <div className="K_Lvh" data-reactid="24"><a className="trackable" href="/" data-reactid="25">
                        <span className="logo_class" name="logo">

                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="841.89px" height="595.28px" viewBox="0 0 841.89 595.28" enable-background="new 0 0 841.89 595.28" space="preserve">
<path fill="#14AADE" d="M408.577,312.789h-87.17c0.32,14.613,4.019,25.894,11.092,33.839c7.072,7.948,16.799,11.921,29.177,11.921
	c13.905,0,26.685-4.87,38.341-14.613v19.739c-10.852,8.375-25.199,12.562-43.043,12.562c-17.443,0-31.148-5.96-41.114-17.879
	c-9.967-11.922-14.95-28.692-14.95-50.311c0-20.421,5.444-37.062,16.337-49.923c10.89-12.86,24.414-19.291,40.57-19.291
	s28.653,5.556,37.497,16.663c8.839,11.109,13.262,26.532,13.262,46.272V312.789z M388.322,294.973
	c-0.083-12.132-2.834-21.576-8.259-28.326c-5.426-6.75-12.961-10.126-22.607-10.126c-9.325,0-17.24,3.547-23.751,10.639
	c-6.511,7.094-10.531,16.364-12.057,27.813H388.322z"/>
<path fill="#14AADE" d="M531.96,373.161h-19.773v-74.855c0-27.856-9.566-41.785-28.695-41.785c-9.886,0-18.067,3.954-24.535,11.856
	c-6.473,7.904-9.706,17.879-9.706,29.929v74.855h-19.772V241.91h19.772v21.789h0.483c9.322-16.577,22.826-24.866,40.511-24.866
	c13.503,0,23.829,4.636,30.985,13.908c7.151,9.272,10.73,22.667,10.73,40.183V373.161z"/>
<path fill="#14AADE" d="M665.591,373.161h-19.774v-22.304h-0.482c-9.162,16.92-23.31,25.379-42.439,25.379
	c-15.513,0-27.91-5.875-37.193-17.624c-9.284-11.747-13.927-27.749-13.927-48.001c0-21.705,5.144-39.094,15.434-52.167
	c10.288-13.073,23.993-19.611,41.113-19.611c16.959,0,29.298,7.094,37.013,21.277h0.482v-81.262h19.774V373.161z M645.816,313.816
	V294.46c0-10.593-3.295-19.566-9.887-26.916c-6.59-7.348-14.95-11.023-25.076-11.023c-12.058,0-21.543,4.701-28.454,14.1
	c-6.914,9.4-10.369,22.387-10.369,38.965c0,15.126,3.315,27.065,9.945,35.825c6.633,8.759,15.534,13.138,26.707,13.138
	c11.011,0,19.954-4.23,26.827-12.69C642.382,337.399,645.816,326.718,645.816,313.816z"/>
<path fill="#14AADE" d="M747.221,376.236c-18.247,0-32.814-6.131-43.706-18.393c-10.891-12.261-16.336-28.519-16.336-48.771
	c0-22.047,5.666-39.265,17.001-51.654c11.332-12.389,26.645-18.586,45.936-18.586c18.405,0,32.772,6.024,43.102,18.073
	c10.328,12.049,15.493,28.756,15.493,50.116c0,20.938-5.566,37.704-16.699,50.309S765.949,376.236,747.221,376.236z
	 M748.668,256.521c-12.701,0-22.748,4.595-30.142,13.779c-7.396,9.187-11.092,21.854-11.092,38.004
	c0,15.553,3.737,27.813,11.212,36.786c7.476,8.973,17.483,13.459,30.021,13.459c12.779,0,22.607-4.402,29.479-13.203
	c6.872-8.799,10.31-21.319,10.31-37.555c0-16.406-3.438-29.051-10.31-37.939C771.275,260.965,761.447,256.521,748.668,256.521z"/>
<g>
	<path fill="#14AADE" stroke="#14AADE" stroke-width="9" stroke-miterlimit="10" d="M153.846,434.381H47.645
		c-15.331,0-26.276-3.437-32.833-10.309c-6.56-6.873-9.838-17.811-9.838-32.823V130.092c0-15.323,3.356-26.342,10.075-33.059
		c6.716-6.713,17.579-10.072,32.596-10.072h112.603c16.594,0,30.973,1.03,43.145,3.081c12.167,2.055,23.072,6.006,32.714,11.849
		c8.215,4.899,15.487,11.102,21.81,18.604c6.319,7.505,11.142,15.8,14.46,24.883c3.319,9.087,4.978,18.685,4.978,28.794
		c0,34.759-17.387,60.194-52.152,76.309c45.67,14.538,68.509,42.817,68.509,84.841c0,19.433-4.978,36.933-14.935,52.492
		c-9.957,15.563-23.391,27.058-40.3,34.481c-10.589,4.425-22.757,7.546-36.507,9.36C188.22,433.474,172.178,434.381,153.846,434.381
		z M75.38,139.809v89.817h64.48c17.542,0,31.092-1.659,40.656-4.977c9.56-3.318,16.868-9.635,21.927-18.959
		c3.949-6.635,5.927-14.06,5.927-22.277c0-17.537-6.245-29.186-18.727-34.955c-12.487-5.766-31.529-8.65-57.131-8.65H75.38z
		 M148.631,280.104H75.38v101.43h75.622c47.567,0,71.354-17.141,71.354-51.426c0-17.537-6.164-30.252-18.49-38.154
		C191.539,284.055,173.126,280.104,148.631,280.104z"/>
</g>
<path fill="none" stroke="#14AADE" stroke-width="9" stroke-miterlimit="10" d="M362.895,146.154
	c14.734-42.095-9.826-88.815-54.912-104.459"/>
<path fill="none" stroke="#14AADE" stroke-width="9" stroke-miterlimit="10" d="M328.183,144.724
	c10.823-27.999-7.575-61.05-41.133-73.897"/>
<path fill="none" stroke="#14AADE" stroke-width="9" stroke-miterlimit="10" d="M295.351,146.373
	c4.417-20.451-8.363-40.473-28.572-44.766"/>
</svg>
    
                   
</span></a></div>
                       
 
        </MediaQuery>




        </div>
   

        <MediaQuery minWidth={971}>

            <div data-reactid="15">
            <div data-reactid="16">
  
              <div className="_1gYyO" data-reactid="17">
                <div className="_3sMB4 _2eaqN" data-reactid="18">
                  <div className="_1AsHn _3Tx6j" data-reactid="19">
                    <div className="_2mtVi" data-reactid="20">
                      Menu
                    </div>
                    <div className="R9zev" data-reactid="22">
                      <div className="qFeQO" data-reactid="23">
                        <div className="K_Lvh" data-reactid="24"><a className="trackable" href="/" data-reactid="25">
                        <span className="logo_class" name="logo">
                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="841.89px" height="595.28px" viewBox="0 0 841.89 595.28" enable-background="new 0 0 841.89 595.28" space="preserve">
<path fill="#14AADE" d="M408.577,312.789h-87.17c0.32,14.613,4.019,25.894,11.092,33.839c7.072,7.948,16.799,11.921,29.177,11.921
	c13.905,0,26.685-4.87,38.341-14.613v19.739c-10.852,8.375-25.199,12.562-43.043,12.562c-17.443,0-31.148-5.96-41.114-17.879
	c-9.967-11.922-14.95-28.692-14.95-50.311c0-20.421,5.444-37.062,16.337-49.923c10.89-12.86,24.414-19.291,40.57-19.291
	s28.653,5.556,37.497,16.663c8.839,11.109,13.262,26.532,13.262,46.272V312.789z M388.322,294.973
	c-0.083-12.132-2.834-21.576-8.259-28.326c-5.426-6.75-12.961-10.126-22.607-10.126c-9.325,0-17.24,3.547-23.751,10.639
	c-6.511,7.094-10.531,16.364-12.057,27.813H388.322z"/>
<path fill="#14AADE" d="M531.96,373.161h-19.773v-74.855c0-27.856-9.566-41.785-28.695-41.785c-9.886,0-18.067,3.954-24.535,11.856
	c-6.473,7.904-9.706,17.879-9.706,29.929v74.855h-19.772V241.91h19.772v21.789h0.483c9.322-16.577,22.826-24.866,40.511-24.866
	c13.503,0,23.829,4.636,30.985,13.908c7.151,9.272,10.73,22.667,10.73,40.183V373.161z"/>
<path fill="#14AADE" d="M665.591,373.161h-19.774v-22.304h-0.482c-9.162,16.92-23.31,25.379-42.439,25.379
	c-15.513,0-27.91-5.875-37.193-17.624c-9.284-11.747-13.927-27.749-13.927-48.001c0-21.705,5.144-39.094,15.434-52.167
	c10.288-13.073,23.993-19.611,41.113-19.611c16.959,0,29.298,7.094,37.013,21.277h0.482v-81.262h19.774V373.161z M645.816,313.816
	V294.46c0-10.593-3.295-19.566-9.887-26.916c-6.59-7.348-14.95-11.023-25.076-11.023c-12.058,0-21.543,4.701-28.454,14.1
	c-6.914,9.4-10.369,22.387-10.369,38.965c0,15.126,3.315,27.065,9.945,35.825c6.633,8.759,15.534,13.138,26.707,13.138
	c11.011,0,19.954-4.23,26.827-12.69C642.382,337.399,645.816,326.718,645.816,313.816z"/>
<path fill="#14AADE" d="M747.221,376.236c-18.247,0-32.814-6.131-43.706-18.393c-10.891-12.261-16.336-28.519-16.336-48.771
	c0-22.047,5.666-39.265,17.001-51.654c11.332-12.389,26.645-18.586,45.936-18.586c18.405,0,32.772,6.024,43.102,18.073
	c10.328,12.049,15.493,28.756,15.493,50.116c0,20.938-5.566,37.704-16.699,50.309S765.949,376.236,747.221,376.236z
	 M748.668,256.521c-12.701,0-22.748,4.595-30.142,13.779c-7.396,9.187-11.092,21.854-11.092,38.004
	c0,15.553,3.737,27.813,11.212,36.786c7.476,8.973,17.483,13.459,30.021,13.459c12.779,0,22.607-4.402,29.479-13.203
	c6.872-8.799,10.31-21.319,10.31-37.555c0-16.406-3.438-29.051-10.31-37.939C771.275,260.965,761.447,256.521,748.668,256.521z"/>
<g>
	<path fill="#14AADE" stroke="#14AADE" stroke-width="9" stroke-miterlimit="10" d="M153.846,434.381H47.645
		c-15.331,0-26.276-3.437-32.833-10.309c-6.56-6.873-9.838-17.811-9.838-32.823V130.092c0-15.323,3.356-26.342,10.075-33.059
		c6.716-6.713,17.579-10.072,32.596-10.072h112.603c16.594,0,30.973,1.03,43.145,3.081c12.167,2.055,23.072,6.006,32.714,11.849
		c8.215,4.899,15.487,11.102,21.81,18.604c6.319,7.505,11.142,15.8,14.46,24.883c3.319,9.087,4.978,18.685,4.978,28.794
		c0,34.759-17.387,60.194-52.152,76.309c45.67,14.538,68.509,42.817,68.509,84.841c0,19.433-4.978,36.933-14.935,52.492
		c-9.957,15.563-23.391,27.058-40.3,34.481c-10.589,4.425-22.757,7.546-36.507,9.36C188.22,433.474,172.178,434.381,153.846,434.381
		z M75.38,139.809v89.817h64.48c17.542,0,31.092-1.659,40.656-4.977c9.56-3.318,16.868-9.635,21.927-18.959
		c3.949-6.635,5.927-14.06,5.927-22.277c0-17.537-6.245-29.186-18.727-34.955c-12.487-5.766-31.529-8.65-57.131-8.65H75.38z
		 M148.631,280.104H75.38v101.43h75.622c47.567,0,71.354-17.141,71.354-51.426c0-17.537-6.164-30.252-18.49-38.154
		C191.539,284.055,173.126,280.104,148.631,280.104z"/>
</g>
<path fill="none" stroke="#14AADE" stroke-width="9" stroke-miterlimit="10" d="M362.895,146.154
	c14.734-42.095-9.826-88.815-54.912-104.459"/>
<path fill="none" stroke="#14AADE" stroke-width="9" stroke-miterlimit="10" d="M328.183,144.724
	c10.823-27.999-7.575-61.05-41.133-73.897"/>
<path fill="none" stroke="#14AADE" stroke-width="9" stroke-miterlimit="10" d="M295.351,146.373
	c4.417-20.451-8.363-40.473-28.572-44.766"/>
</svg>



</span></a></div>
                        <div className="_2ITMP" data-reactid="27">
                          <nav className="_2cE2d" data-reactid="28">
                            <ul className="ZGBSO" data-reactid="29"><a className="_3trbw trackable" title="Accueil" href="/" data-reactid="30">
                                <li data-reactid="31">
                                Accueil
                                  <div className="_3wQEb" data-reactid="33"><span className="_1vK7W _1eOK1" name="menuUp">
                                      <svg height="32" width="32" viewBox="0 0 32 32">
                                        <path d="M0 24L16 8l16 16H0z" fill="#0D6DAE"></path>
                                      </svg></span></div>
                                </li>
                              </a><a className="_3trbw trackable" title="Produits" href="#/products/PEA_OEA" data-reactid="35">
                                <li data-reactid="36">
                                Produits
                                  <div className="_3wQEb" data-reactid="38"><span className="_1vK7W _1eOK1" name="menuUp">
                                      <svg height="32" width="32" viewBox="0 0 32 32">
                                        <path d="M0 24L16 8l16 16H0z" fill="#0D6DAE"></path>
                                      </svg></span></div>
                                </li>
                              </a>
                              <a className="_3trbw trackable" title="Activités" href="#/rdlink/R_D" data-reactid="40">
                                <li data-reactid="41">
                     
                                                          <div  className="dropdown" >
                                  <div className="button_menu_activity" onClick={this.showDropdownMenu}> Activités </div>

                                    { this.state.displayMenu ? (
                                    <ul>
                                  <li><a title="Recherche-développement" className="active" href="#/rdlink/R_D"  >R&D</a></li>
                                  <li><a title="Campagnes de dépistage" href="#/campagnelink" >Dépistage</a></li>
                              
                                    </ul>
                                  ):
                                  (
                                    null
                                  )
                                  }

                                  </div>
                                    
                                  <div className="_3wQEb" data-reactid="43"><span className="_1vK7W _1eOK1" name="menuUp">
                                      <svg height="32" width="32" viewBox="0 0 32 32">
                                        <path d="M0 24L16 8l16 16H0z" fill="#0D6DAE"></path>
                                      </svg></span></div>
                                </li>
                              </a><a className="_3trbw trackable" title = "  A propos de BENDO" href="#/aPropos" data-reactid="50">
                                <li data-reactid="51">
                                A propos de BENDO
                                  <div className="_3wQEb" data-reactid="53"><span className="_1vK7W _1eOK1" name="menuUp">
                                      <svg height="32" width="32" viewBox="0 0 32 32">
                                        <path d="M0 24L16 8l16 16H0z" fill="#0D6DAE"></path>
                                      </svg></span></div>
                                </li>
                              </a>
                              </ul>
                          </nav>
                        </div>
                      </div>
                      <div className="s_gWv" data-reactid="60">
                        <div className="Of2CC" data-reactid="61">
                          <div className="_10hbi" data-reactid="62">
                            <div className="wrfZu trackable" data-qa-id="profilarea-login">
                              <div><span className="_1vK7W" name="user">
                              <svg viewBox="0 0 24 24" data-name="Calque 1" focusable="false">
                  <path d="M21.65 16.37l-3.4-.37a2.69 2.69 0 0 0-2.19.76l-2.45 2.44a20.11 20.11 0 0 1-8.81-8.81l2.47-2.47A2.61 2.61 0 0 0 8 5.73l-.35-3.37A2.68 2.68 0 0 0 5 0H2.68A2.66 2.66 0 0 0 0 2.77 22.71 22.71 0 0 0 21.23 24 2.66 2.66 0 0 0 24 21.32V19a2.64 2.64 0 0 0-2.35-2.63z">
                  </path>
                </svg></span></div>
                              <div className="_1SXgw"> <a href="#contactUs">Contactez-nous</a></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
          </MediaQuery>

        
        </header>
        );
    }
}

export default Header;