import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import ReactDOM from 'react-dom';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import config from '../../config/config';
import "antd/dist/antd.css";
import LinesEllipsis from 'react-lines-ellipsis';
import { BrowserRouter, Route, Link } from "react-router-dom";
//import Pagination from '../component/pagination';
import { Pagination } from 'antd';
import ressouces from '../../ressources/ressouces';
const numEachPage = 4;
class ImgMediaCardBendo extends Component {
    constructor(props) {
        super(props);
        this.state = {
          }
        };

render () {
{/* <Card className={classes.root}> */}
return (
  <ThemeProvider theme={theme}>
    <Card>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="140"
          image={this.props.image}
          //image="/static/images/cards/contemplative-reptile.jpg"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {this.props.title}
          </Typography>
          
          <Typography variant="body1" color="textSecondary" component="p">
            <LinesEllipsis
  text= {this.props.content}
  maxLine='3'
  ellipsis='...'
  trimRight
  basedOn='letters'
/>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {/* <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button> */}
      <Link to={this.props.lien}>
              Plus ...
            </Link>
      </CardActions>
    </Card>
    </ThemeProvider>)
}
}
export default ImgMediaCardBendo;

const breakpoints = createBreakpoints({});
const theme = createMuiTheme({
  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      // fontWeight: 500,
      fontSize: 20,
    },
    button: {
      fontStyle: 'italic',
    },
  },
});
function pxToRem(value) {
  return `${value / 16}rem`;
}