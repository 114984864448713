import React from "react";
import ressouces from '../../ressources/ressouces'
import config from '../../config/config';
const R_d_app_dep_surd = (title,description,image) => {
    return (
        <div className="dev_background">
<div className="row dev_header">
<div className="col-xs-12 col-sm-12 col-md-12">
    <h2 className="row">
    {ressouces.ACTIVITY.R_D.case1.title}
    </h2>
</div>
</div>
<div className="row">
<div className="col-xs-12 col-sm-6 col-md-6">
<p className="dev_description ">
{ressouces.ACTIVITY.R_D.case1.description}
</p>
</div>
<div className="col-xs-12 col-sm-6 col-md-6">
<img src={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case1.img1}/> 
</div>
</div>
</div>
    )
   }
   export default R_d_app_dep_surd;