import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Parser from 'html-react-parser';
import ressouces from '../../ressources/ressouces';
class Partenaire extends Component {
    constructor(props) {
        super(props);
        this.state = {
          }
        };
    render() {
        return (
        <div className="row partenaire">
            
            <div className="col-xs-12 col-sm-12 col-md-12 divider"></div>
            <div className="col-xs-12 col-sm-12 col-md-12 content_partenaire">
                <div className="row">
                    <div className="col-xs-12 col-sm-4 col-md-4">
                    <img src="og-image-logo.png" alt="Girl in a jacket"></img>
                    </div>
                    {/* <div className="col-xs-12 col-sm-8 col-md-4">
                    <img src="og-image-logo.png" alt="Girl in a jacket"></img>
                    </div> */}
                    <div className="col-xs-12 col-sm-8 col-md-4">
                    <img src="timeIncubating.png" alt="Girl in a jacket"></img>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 divider"></div>
        </div>)
    }
}
export default Partenaire;