import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import MediaCardBendo from '../components/MediaCardBendo';
import "antd/dist/antd.css";
import ressouces from '../../ressources/ressouces';
//import Pagination from '../component/pagination';
import { Pagination } from 'antd';
const numEachPage = 4;
class Actualities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            todosPerPage: 3
          }
        };
 handleChange = value => {
    // alert(value);
            this.setState({
                currentPage: value  
            });
          };

render () {
   // const classes = useStyles();
   
   let campagnes = [
    { date:"09/2021",title: "Déploiement des appareils de dépistage de la surdité à, l’hôpital régional de Bertoua", body: "<p>Grace aux équipements de Bendo, l’hôpital régional de Bertoua a mis en œuvre au mois de Septembre 2021 son  <strong>programme de dépistage systématique de la surdité dès la naissance</strong>. Une formation du personnel médical relative à l’utilisation de l’appareil s’est tenue sur 4 Jours.</p>" ,pathImage:["/bertoua/1631027620867_IMG_20210831_163040.jpg","/bertoua/IMG_20210831_163138.jpg","/bertoua/IMG_20210901_095323_868_jpg.jpg","/bertoua/IMG_20210901_095212_573_jpg.jpg","/bertoua/IMG_20210901_095633_386_jpg.jpg","/bertoua/IMG_20210901_095900_864_jpg.jpg"],id:"10",data_target:"#10"},
    { date:"05/2021",title: "Session de formation du personnel ORL de l’hôpital gynéco sur la prothèse BALAFON", body: "<p>Bendo forme le personnel médical sur l 'utilisation des prothèses auditives BALAFON réalisées par Bendo. </p><p>Le personnel médical de <a href=\"https://www.minsante.cm/site/?q=en/content/h%C3%B4pital-gyn%C3%A9co-obst%C3%A9triuque-et-p%C3%A9diatrique-de-douala-hgoped\" target=\"_blank\">hôpital gynéco obstetrique de Douala (HGOPED)</a> a pris part à la première session de formation organisé en Mai 2021.</p>" ,pathImage:["/Gyneco/DSC_0554.JPG","/Gyneco/DSC_0563.JPG","/Gyneco/DSC_0585.JPG"],id:"9",data_target:"#9"},
    { date:"05/2021",title: "Déploiement des appareils de dépistage de la surdité au Gabon ", body: "<p>Bendo a déployé les appareils de dépistage de la surdité au Gabon en Mai 2021.Une formation du personnel médical relative à l’utilisation de l’appareil s’est tenue sur 4 Jours.</p>" ,pathImage:["/Gabon/IMG_20210525_162456_718.jpg","/Gabon/IMG_20210527_173947_639.jpg"],id:"8",data_target:"#8"},
    { date:"04/2020",title: "Déploiement des appareils de dépistage Son'Or au Mozambique", body: "<p>Installation des appareils Bendo au Mozambique, 1er pays de l’afrique du sud à valoriser le savoir-faire de la startup qui apporte une contribution à la recherche de solutions aux problèmes de la surdité en Afrique.</p>" ,pathImage:["/Mozambique/IMG_7962.JPG","/Mozambique/IMG_20200312_104133_1.jpg","/Mozambique/IMG_20200313_103706_4.jpg","/Mozambique/IMG_8125.JPG"],id:"6",data_target:"#6"},
    { date:"03/2021",title: "Participation au 15éme Congres de la Société Camerounaise D'ORL", body: "<p>Bendo a pris part au 15ème Congres de la Société Camerounaise D'ORL  tenue à l'Hôtel La Falaise Bonanjo, Douala du  26 au 27 Mars 2021</p>" ,pathImage:["/CongresOrl/DSC_0162.JPG","/CongresOrl/DSC_0661.JPG","/CongresOrl/IMG_20210327_091823_457.jpg"],id:"6",data_target:"#6"},
    { date:"05/2020",title: "Prix Challenge des 1000 Entrepreneurs", body: "<p>Bendo est lauréat du prix <strong>Challenge des 1000 Entrepreneurs </strong>organisé par <strong>Digital Africa</strong>.Rendez-vous au <strong>Sommet Afrique France 2020</strong>!!!</p>" ,pathImage:["/Digital_Afr_Stor/IMG_20200207_131025_1.jpg"],id:"1",data_target:"#1"},
    { date:"03/2020",title: "CAMPAGNE A L’HÔPITAL GÉNÉRAL DE YAOUNDÉ", body: "<p>À l'occasion de la journée mondiale de la surdité, une Campagne de <strong>dépistage de la surdité </strong>sera organisée à <strong>l’Hôpital Général de Yaoundé le 03 Mars 2020</strong>.</p>" ,pathImage:["/Hoputal_General/IMG_4570.JPG","/Hoputal_General/IMG_4527.JPG"],id:"2",data_target:"#2"},
    { date:"02/2020",title: "Prix Pitch My App Digital Africa Story", body: "<p>Félicitation à la Startup <strong>Bendo</strong> grand gagnant du concours <strong>PitchMyApp</strong> de l’événement Douala Digital Story <strong>DDS2020</strong> qui gagne un billet d’avion pour assister à la grande finale à Kinshasa lors du sommet African Digital Story en Avril ; Un billet d’avion et un pass VIP pour assister à <strong>VivaTech</strong> en juin à Paris et une assurance maladie et accident de Atlantique assurance sponsor de ce concours.</p><p><a href=\"https://www.facebook.com/doualadigitalstory\/\">Douala Digital Story</a></p>" ,pathImage:["/Digital_Afr_Stor/IMG-20200210-WA0009.jpg"],id:"0",data_target:"#0"},
    { date:"11/2019",title: "HÔPITAL MILITAIRE DE GAROUA", body: "<p>Campagne de dépistage à <strong>l’hôpital militaire de Garoua</strong> en Novembre 2019. </p>" ,pathImage:["/Garoua/IMG_20191125_14.jpg","/Garoua/IMG_4468.JPG"],id:"4",data_target:"#4"},
    { date:"06/2019",title: " CAMPAGNE A L’HÔPITAL GYNÉCO OBSTÉTRIQUE ET PÉDIATRIE DE DOUALA", body: "Campagne de dépistage à <strong>l’hôpital gynéco obstétrique et pédiatrie de Douala en juin 2019</strong> . Des patients sont massivement attendus.",pathImage:["/Douala/IMG_20190603_091634_6.jpg","/Douala/Image5.png"],id:"3",data_target:"#3"},
    { date:"12/2018",title: "CAMPAGNE A LA FONDATION CHANTAL BIYA", body: "<p>Une Campagne est prévue à <strong>la Fondation Chantal Biya en Décembre 2018</strong></p>" ,pathImage:["/Chantal_Biya/IMG_20181203_142924_0.jpg","/Chantal_Biya/IMG_20181205_093851_6.jpg"],id:"5",data_target:"#5"},
  ];
  const { currentPage, todosPerPage } = this.state;

  // Logic for displaying todos
  const indexOfLastTodo = currentPage * todosPerPage;
  const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
  const currentTodos = campagnes.slice(indexOfFirstTodo, indexOfLastTodo);

  const renderTodos = currentTodos.map((todo, index) => {
    return <li key={index}>{todo}</li>;
  });
  return (
      
      <div className="Contenaire_Mediacards">
          <div className="row">
          {/* <Pagination/> */}
          {campagnes &&
            campagnes.length > 0 &&
            campagnes.slice(indexOfFirstTodo, indexOfLastTodo).map(campagne => (
                <div className="col-xs-12 col-sm-8 col-md-4">
            {/* <MediaCardBendo date={val.day} title={val.title} body={val.body} pathImage={val.pathImage}id={val.id}data_target={val.data_target}/> */}
            <MediaCardBendo campagne={campagne}/>
            </div>
            ))}
            </div>
            <div className="row pagination_actualite">
            <Pagination
            defaultCurrent={1}
            defaultPageSize={4} //default size of page
            onChange={this.handleChange}
            total={10} //total number of card data available
          />
            </div>
          
         
  </div>

  );
}
}
export default Actualities;
