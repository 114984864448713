import React from "react";
import PropTypes from "prop-types";
import MaterialTitlePanel from "./material_title_panel";
import config from '../../../config/config'

const styles = {
  sidebar: {
    width: 256,
    height: "100%"
  },
  sidebarLink: {
    display: "block",
    padding: "16px 0px",
    color: "#757575",
    textDecoration: "none"
  },
  divider: {
    margin: "8px 0",
    height: 1,
    backgroundColor: "#757575"
  },
  content: {
    padding: "16px",
    height: "100%",
    backgroundColor: "#fafafa"
  }
};

const SidebarContent = props => {
  const style = props.style
    ? { ...styles.sidebar, ...props.style }
    : styles.sidebar;

  const links = [];

  for (let ind = 0; ind < 1; ind++) {
    links.push(
      <a key={ind} href="https://www.facebook.com/Bendo_Innov-110489533948556/" style={styles.sidebarLink}>
        Rejoignez-nous sur Facebook!
      </a>
    );
  }

  return (
    <MaterialTitlePanel className="menuRightBar" title="Menu" style={style}>
      <div style={styles.content}>
        <a href="/" style={styles.sidebarLink}>
        Accueil
        </a>
        <a href="#/products/PEA_OEA" style={styles.sidebarLink}>
        Produits
        </a>

        <a href="#/rdlink/R_D" style={styles.sidebarLink}>
        Activités
              <ul>
                                  <li><a title="Recherche-développement"  href="#/rdlink/R_D"  >R&D</a></li>
                                  <li><a title="Campagnes de dépistage" href="#/campagnelink" >Dépistage</a></li>
                              
                                    </ul>
        </a>
        <a href="#/aPropos" style={styles.sidebarLink}>
        A propos de BENDO
        </a>
    <a href="/#contactUs" style={styles.sidebarLink}>
        Contacter-nous
        </a>
        <div style={styles.divider} />
        {links}
      </div>
    </MaterialTitlePanel>
  );
};

SidebarContent.propTypes = {
  style: PropTypes.object
};

export default SidebarContent;
