import React, { Component } from "react";
import ReactDOM from "react-dom";
import MediaQuery from 'react-responsive';
import {ButtonToolbar, Button,Form, FormGroup,FormCheck, ControlLabel, FormControl,Row,Col} from 'react-bootstrap';
import config from '../../config/config'
import axios from "axios";

class SearchForm extends React.Component{
      
    constructor() {
      super();
      this.state = {
        selectedRegion: "Choisissez une région",
        listesville:["Yaoundé","Douala","Maroua","Meiganga","Bertoua"],
        params:{
            type: "appartement",
            balcon: "non",
            barriere: "non",
            chambre: "1",
            douche: "1",
            etat: "moderne",
            ville: "Yaoundé",
            parking: "oui"},
        detail: false
      }

   
      this.loadsearchForm = this.loadsearchForm.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this)
    }


    handleSubmit(event) {
      event.preventDefault();
      // pass the input field value to the event handler passed
      // as a prop by the parent (App)
      this.props.handlerFromParant(this.state.params);
      
      }
   
    
      handleChange(event) {
        let nom = event.target.name;
        let imagestmp = [];
        var k = 0;
       let paramstmp = this.state.params;
    
        
        if(nom ==="type"){
    
          if((event.target.value=="appartement")||(event.target.value=="maison")){
    
            this.setState({detail : true});
    
          }else{
            this.setState({detail : false});
          }
    
    
        }
          paramstmp[nom] = event.target.value;
          this.setState({params : paramstmp});
    
        
          event.preventDefault();
    
      }

    loadsearchForm(){

        return <div className="form_search_result"> 
         <Form className="form_confiez_cles_main" method="post" action="/addlogement" enctype="multipart/form-data">
          <fieldset>
          <Row>
                <FormGroup >
                    <ControlLabel>Ville </ControlLabel>
                    <FormControl name = "ville" onChange={this.handleChange}
                        inputRef={ el => this.inputVille=el } 
                        componentClass="select" placeholder="ville">
                        {this.state.listesville.map((ville)=>
                                <option value = {ville}>{ville}</option>
                                )}
        
                
                    </FormControl>             
                </FormGroup>
        
                <FormGroup >
                    <ControlLabel>Quartiers</ControlLabel>
                    <FormControl type="text" name = "quartier" placeholder="lister les quartiers où vous souahitez trouver un logement(Emana, nkoabang, mendong,..)" onChange={this.handleChange} inputRef={ el => this.inputQuartier=el }  />
                </FormGroup>
        
        
                <FormGroup >
                <ControlLabel>Type</ControlLabel>
                <FormControl  name ="type"
                        inputRef={ el => this.inputType=el }
                        componentClass="select" onChange={this.handleChange}>
                    <option value="studio">Studio</option>
                    <option value="appartement">Appartement</option>
                    <option value="chambre">Chambre</option>
                    <option value="maison">Maison</option>
                    <option value="boutique">Boutique</option>
                    <option value="magasin">Magasin</option>
                </FormControl>
                </FormGroup>
        
                <FormGroup >
                <ControlLabel>Catégorie</ControlLabel>
                <FormControl  name = "etat" onChange={this.handleChange}
                        inputRef={ el => this.inputCategorie=el }
                        componentClass="select" placeholder="catégorie">
                    <option value="moderne">Moderne</option>
                    <option value="simple">Simple</option>
                </FormControl>
                </FormGroup>
                {this.state.detail==false? "": < Detail onChange={this.handleChange}/>}
        </Row>
 

        <Row>  
   
                <FormGroup >
                <ControlLabel>Balcon</ControlLabel>
                <FormControl name = "balcon" onChange={this.handleChange}
                    inputRef={ el => this.inputBalcon=el }
                    componentClass="select" placeholder="Balcon">
                    <option value="">peu importe</option>
                    <option value="non">Non</option>
                    <option value="oui">Oui</option>
                </FormControl>
            </FormGroup>
        
            <FormGroup >
                <ControlLabel>Barrière</ControlLabel>
                <FormControl name = "barriere" onChange={this.handleChange}
                    inputRef={ el => this.inputBarriere=el }
                    componentClass="select" placeholder="barriere">
                    <option value="">peu importe</option>
                    <option value="non">Non</option>
                    <option value="oui">Oui</option>
                </FormControl>
            </FormGroup>
        
            <FormGroup >
                <ControlLabel>Parking</ControlLabel>
                <FormControl name = "parking" onChange={this.handleChange}
                    inputRef={ el => this.inputParking=el }
                    componentClass="select" placeholder="parking">
                    <option value="">peu importe</option>
                    <option value="non">Non</option>
                    <option value="oui">Oui</option>
                </FormControl>
            </FormGroup>
           
         <Button className="search_btn" variant="primary" type="submit" onClick = {this.handleSubmit} bsSize ="sm" >
                    Chercher
                    </Button>
        
             
         </Row>
            </fieldset> 
            </Form>
     </div>
   
        
      }



    render() {
        return <div>
             {this.loadsearchForm()}
      </div>
    }

  }


  class Detail extends React.Component{
      
    constructor() {
      super();
   
    }
    render() {
        return <div> <Row>
            <FormGroup >
        <ControlLabel>Chambres</ControlLabel>
        <FormControl name = "chambre" onChange={this.props.onChange}
              inputRef={ el => this.inputChambre=el }
              componentClass="select" placeholder="Chambre">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="plus">plus de 3</option>
        </FormControl>
      </FormGroup>
  
  
      <FormGroup >
        <ControlLabel>Douche</ControlLabel>
        <FormControl name = "douche" onChange={this.props.onChange}
              inputRef={ el => this.inputNberDouche=el }
              componentClass="select" placeholder="douche">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="plus">plus de 2</option>
        </FormControl>
      </FormGroup>
      </Row>
      </div>
    }
  }
    

  export default SearchForm;