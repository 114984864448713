import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./../../css/searchcss.css"
import Pagination from "react-js-pagination";
import Header from './header'
import Footer from './footer'
import config from '../../config/config'
import ressouces from '../../ressources/ressouces'

import MediaQuery from 'react-responsive';

import { Link, animateScroll as scroll } from "react-scroll";
import { Icon } from 'react-icons-kit'
import { email } from 'react-icons-kit/entypo'
import { phoneCall } from 'react-icons-kit/feather';



const options = {
    items: 4,
}
class ContactUs extends React.Component {


  constructor(props) {
    super();
    this.state = {
      photos:[
             "https://res.cloudinary.com/serpoma/image/upload/v1584717509/PEA_OEA_Ordinateur.png",
             "https://res.cloudinary.com/serpoma/image/upload/v1584717514/Image_pour_ordinateur.png",
             "https://res.cloudinary.com/serpoma/image/upload/v1584717510/OEA_Tablette.png",
             "https://res.cloudinary.com/serpoma/image/upload/v1584717512/Guy.png",
             "https://res.cloudinary.com/serpoma/image/upload/v1586537373/interface_prothese1.png",
             "https://res.cloudinary.com/serpoma/image/upload/v1584717506/Proth%C3%A8se_Balafon_extrait.png"],
      
    }
    
  }


  
 
componentDidMount() {
  //var url = config.SERVER_ADRESSE + 'trouver-un-logement/liste-de-ma-recherche?region=Centre&type=Studio';

   this.scrollToBottom()
}
 


  componentWillMount(){

    
    this.scrollToBottom()
  }


  scrollToBottom = (x) => {

    scroll.scrollTo(x);
};


  gotoLink(){

    return  <Link
          activeClass="active"
          to="carDescriptionBoxes"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
      >
      </Link>
  }


  loaContenue(){

    return   <div >
   
          <div className="homeActivitiesContainer">

            <div className="contenu1_contact_us">
                <div className="titre_activite_div_contactUs">
                
                <h1 className="titre_activite_contactUs">felicite.bekono@yahoo.com </h1>
                </div>  
                <div className="titre_activite_div_contactUs">
                
                <h1 className="titre_activite_contactUs"><Icon icon={phoneCall} />+237 675 457 627 </h1>
                </div>  
                <div className="titre_activite_div_contactUs">
                
                <h1 className="titre_activite_contactUs"><Icon icon={phoneCall} />+237 698 990 653 </h1>
                </div> 
                <div className="titre_activite_div_contactUs">
                
                <h1 className="titre_activite_contactUs"><Icon icon={phoneCall} />+237 698 990 653 </h1>
                </div>


                </div>   


            <div>

            <div className="photos_caroussel">
                    
                    
                    <OwlCarousel
                    className="owl-theme"
                    loop
                    items={1}
                    margin={10}
                    dots={false}
                    nav
                    navText = {[
                        "<i class='icon-chevron-left icon-white'><</i>",
                        "<i class='icon-chevron-right icon-white'>></i>"
                        ]}
                    >
                    

                    {this.state.photos.map((imge)=> 
                        <div className="item" ><img  src={"https://res.cloudinary.com/lepiston/image/fetch/h_700/"+imge}/></div>
                    )}

                    </OwlCarousel>
                   
              </div>
            </div>

    </div>       
    </div>
  }

  render()
  {

    const { open } = this.state;

    return (
      <div >
    
    {/* <Header/> */}
      {this.loaContenue()}
              <Footer/>
            
              
    
          </div>

    )
  }
}

export default ContactUs;