module.exports = {
    ACTIVITY:{ 
       R_D:{
          title:"Recherche-Développement",
         description:"Notre Vision est d’offrir à tous une chance d’accéder à des soins de qualité grâce à des technologies innovantes et adaptées au contexte socio-économique des pays d’Afrique Subsaharienne. Pour y parvenir, un accent est porté sur la recherche-développement au sein de la Start up Camerounaise BENDO ayant à son actif plusieurs brevets d’inventions. A ce jour, les axes de R&D au sein de la Start up portent sur les appareils de dépistage de la surdité néonatale, les appareils de réhabilitation de la surdité, les appareils de régulation de l’énergie électrique et les systèmes embarqués.",
         img1:"https://res.cloudinary.com/serpoma/image/upload/v1584720118/rd_recherche_developpement.png",
         case1:{
               title:"Les appareils de dépistage de la surdité néonatale",
               description:"L’appareil de dépistage de la surdité néonatale baptisé « Son’Or » est une réponse à la problématique du dépistage néonatal de la surdité au Cameroun et en Afrique Subsaharienne en général. Ce projet voit le jour dans un contexte où moins de 1% des formations sanitaires du Cameroun disposent de cet équipement."+
               "Afin d\'être adapté de manière contextuelle aux problèmes que rencontres les professionnels de santé « Son ’Or » a été développé en collaboration avec des ingénieurs, techniciens, médecins et patients cibles au Cameroun. Cette approche a conduit dans sa phase de développement à plusieurs innovations technologiques logicielles et matérielles. Depuis 2018, « Son’Or » est utilisé dans une dizaine d\'établissements de santé au Cameroun et au Mozambique.",
               img1:"https://res.cloudinary.com/serpoma/image/upload/v1586518223/b00092.png"
         },
         case2:{
            title:"Les prothèses auditives",
            description:"Les appareils de réhabilitions auditives de la surdité (prothèses auditives) baptisés « Balafon » sont une réponse à la problématique de l’insuffisance criarde des prothèses auditives et des audioprothésistes au Cameroun et en Afrique Subsaharienne en général. Les personnes atteintes de déficience auditive peuvent voir leur état amélioré par l’utilisation de dispositifs tels que les prothèses auditives, les implants cochléaires et les dispositifs d’aide à l’audition. Cependant, quelques données fournies par l’OMS indiquent que la production mondiale de prothèses auditives répond à moins de 10% de l'ensemble des besoins et à moins de 3% de ceux des pays en développement.",
            description2:" Le manque de service d’adaptation et d’entretien des appareils auditifs et le manque de piles sont également des obstacles dans beaucoup de pays à faible revenu."+
            "Afin d'être adapté de manière contextuelle, les choix technologiques pour le développement de « Balafon » découlent des travaux collaboratifs entre ingénieurs, techniciens, médecins et patients cibles au Cameroun. Cette approche a conduit à plusieurs innovations technologiques logicielles et matérielles. Balafon est une prothèse numérique recommandée pour les personnes présentant les signes de surdité légère à sévère, elle est paramétrable à distance et est dotée d’une batterie rechargeable. « Balafon» est facile d’utilisation et d’entretien.",
            img1:"https://res.cloudinary.com/serpoma/image/upload/v1586518223/b00102.png"
         },
         case3:{
            title:"Les appareils de régulation de l’énergie électrique",
            description:"Les appareils de régulation de l’énergie électrique développés par BENDO sont une contribution à la problématique de la défectuosité des équipements médicaux dans les formations sanitaires suites aux perturbations du réseau électrique. Quelques données fournies par la Banque Mondiale indiquent que plus de 50% de l’équipement médical dans les pays en développement ne fonctionne pas. Celles de l’OMS indiquent que jusqu’à 1/3 des pannes d’appareils médicaux sont causées par un réseau électrique défaillant.",
            description2:"En 2019, BENDO a développé et réalisé des régulateurs de tension à contrôle numérique baptisé « ControlPlus » ainsi que des onduleurs baptisés «Prevent » offrant la possibilité de choisir la topologie de fonctionnement « In-Line» ou « On-Line » dépendamment de la nature et de la fréquences des coupures électriques. Ces systèmes de régulation de l’énergie électrique sont utilisés dans plusieurs hôpitaux et en particulier dans les zones rurales.",
            img1:"https://res.cloudinary.com/serpoma/image/upload/v1586518224/b00032.png"
         },
         case4:{
            title:"R&D des systèmes embarqués",
            description:"Entre autre développement, BENDO a finalisé en 2020 un système électronique embarqué destiné aux pharmacies. Ce système devrait rentrer dans une phase de déploiement en 2021. Les résultats obtenus à ce jour rendent ce système utilisable par un particulier en fonction du besoin.",
            img1:"https://res.cloudinary.com/serpoma/image/upload/v1586537373/interface_prothese1.png"
         }

         },

         CAMPAGNE:{
            title:"Objectifs des campagnes de dépistage de la surdité",
            description:"Les campagnes de dépistage de la surdité visent à :",
            description2:[
               "Sensibiliser sur le problème de la surdité au Cameroun",
               "Informer sur la nécessité du dépistage systématique de la surdité dès la naissance ",
               "Sensibiliser sur l’intérêt du dépistage de la surdité en âge préscolaire",
               "Faire connaitre l’état d’avancement des dispositifs local pour le dépistage de la surdité et les moyens de prise en charge aux personnes malentendantes et aux professionnels de la santé comme le cas des appareils de dépistage de la surdité fabriqués par Bendo une star up camerounaise et qui est l’appareil par excellence utilisé lors des dernières campagne au Cameroun",
               "Permettre une prise en charge précoce des personnes dépiste sourd par le biais des prothèses auditives fabriquées par la star up  Bendo et des implants cochléaire déjà disponible au Cameroun ",
               "Améliorer la qualité de vie de personnes sourdes",
               "Faciliter l’accès à l’acquisition des appareils de dépistage et des prothèses auditives au professionnel de la santé"

            ],
            imgs:[
               "https://res.cloudinary.com/serpoma/image/upload/v1594220932/h_general.png",
               "https://res.cloudinary.com/serpoma/image/upload/v1594220928/Affiche_Campagne_Douala.png"
             
            ],

            case1:{
               title:"Fondation Chantal Biya",
               description:"La première campagne a eu lieu à la Fondation Chantal Biya en Décembre 2018. Plus de 500 tests ont été effectués en une semaine et plusieurs cas de surdité ont été pris en charge ",
               img1:"https://res.cloudinary.com/serpoma/image/upload/v1586436053/camapagne/Chantal_Biya.png"
            },
            case2:{
               title:"Hôpital gynéco obstétrique et pédiatrie de Douala",
               description:"La deuxième campagne a eu lieu à l’hôpital gynéco obstétrique et pédiatrie de Douala en juin 2019. Plus de 600 examens effectués en une semaine et les cas de surdité ont été pris en charge               ",
               img1:"https://res.cloudinary.com/serpoma/image/upload/v1586435189/camapagne/HGOPD_extrait_de_Video.png"
            },
            case3:{
               title:"Hôpital militaire de Garoua",
               description:"La troisième campagne a eu lieu à l’hôpital militaire de Garoua en Novembre 2019. Plus de 150 tests en 3 jours et les cas de surdité ont été pris en charge",
               img1:"https://res.cloudinary.com/serpoma/image/upload/v1586435947/camapagne/Garoua.jpg"
            },  
            case4:{
               title:"Hôpital général de Yaoundé",
               description:"La quatrième campagne a eu lieu à l’hôpital général de Yaoundé en mars 2020 à l’occasion de la journée mondiale de la surdité. Plus de 100 examens effectués ce jour et les cas de surdité ont été  pris en charge",
               img1:"https://res.cloudinary.com/serpoma/image/upload/v1586435851/camapagne/HFY.jpg"
            },
         },


         IT:{

         }
   },


     PRODUCTS:{
        PEA_OEA:{
           title:"Appareils de dépistage son’0r (PEA)",
           description:"L’appareil PEA Son ‘Or est idéal pour le diagnostic approfondi de la surdité. C’est un appareil deux en un car il permet d’effectuer les examens PEA et OEA. Il est doté d’un système de traitement du signal performant et fonctionne sous Androïd et Linux.",
           images:{img1:"https://res.cloudinary.com/serpoma/image/upload/v1584717509/PEA_OEA_Ordinateur.png",
                  img2:"https://res.cloudinary.com/serpoma/image/upload/v1585756190/PEA_OEA.png"}

        },

        OEA:{
         title:"Appareil de dépistage OEA",
         description:"L’appareil OEA Son’Or est idéal pour le dépistage et le diagnostic précoce de la surdité chez le nouveau-né. Il est facilement transportable et dispose d’une batterie permettant de garantir une autonomie d’une semaine. Il est doté d’un système de traitement du signal performant et fonctionne sous Androïd et Linux.",
         images:{img1:"https://res.cloudinary.com/serpoma/image/upload/v1584717510/OEA_Tablette.png",
                  img2:"https://res.cloudinary.com/serpoma/image/upload/v1584717512/Guy.png"}
        },

        PROTHESE:{
         title:"Prothèse auditive Balafon",
         description:"Prothèse de corps numérique, indiquée pour les personnes présentant les signes de surdités légères, profondes ou sévères. Elle est paramétrable à distance sur plusieurs bandes fréquentielles et est dotée d’une batterie rechargeable (24h d’autonomie en fonctionnement continu). Elle est facile d’utilisation et d’entretien.",
         images:{img1:"https://res.cloudinary.com/serpoma/image/upload/v1584717506/Proth%C3%A8se_Balafon_extrait.png",
                  img2:"https://res.cloudinary.com/serpoma/image/upload/v1584717514/Screenshot_2018-10-16-05-38-40.png"}
        }

     }
     
 }