import React from "react";
import Main from "./js/components/Main";
import Footer from '../src/js/components/footer';
import Header from '../src/js/components/header';
import Partenaire from '../src/js/components/partenaires';

const App = () => (
    <div className="backgroundApp">
          <div>
            <Header />
          </div>
 <div className="container">
<Main /> 
  {/* <Partenaire/> */}
 </div>
{/*  <div className="row kk">
 <Footer />
</div> */}
</div>
);

export default App;