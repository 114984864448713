import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./../../css/searchcss.css"
import Pagination from "react-js-pagination";
import Header from './header'
import Footer from './footer'
import ContactUsprintBar from './ContactUsprintBar'
import SearchForm from './SearchForm'
import DistanceDiv from './DistanceDiv'
import axios from "axios";
import MediaQuery from 'react-responsive';
import { Icon } from 'react-icons-kit'
import { time } from 'react-icons-kit/ikons'
import { home } from 'react-icons-kit/icomoon/home'
import { bed,shower,building } from 'react-icons-kit/fa'


import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  EmailShareButton,
} from 'react-share';



const config = require('../../config/config');

const options = {
    items: 4,
}
class ResultSearch extends React.Component {


  constructor(props) {
    super();
    this.state = {
      activePage: 15,
      respons: null,
      params:{
        type: "appartement",
        balcon: "non",
        barriere: "non",
        chambre: "1",
        douche: "1",
        etat: "moderne",
        ville: "Yaoundé",
        parking: "oui"}
    }
  

    this.handleData = this.handleData.bind(this);
    this.loadDetail = this.loadDetail.bind(this);
  }
  
  async getData() {
    var url = config.SERVER_ADRESSE + 'logement/read';
   // var url = config.SERVER_ADRESSE+"show-by-criteria/"+localStorage.getItem('requete1');
    const res = await axios.get(url);
    const { data } = await res;
    this.setState({respons: data})
}
  

  componentDidMount() {
    //var url = config.SERVER_ADRESSE + 'trouver-un-logement/liste-de-ma-recherche?region=Centre&type=Studio';
    
      this.getData()

  }
  componentWillMount(){
    var url = config.SERVER_ADRESSE+'logement/show-by-criteria?'+localStorage.getItem('requete1');
    //axios.get(url);
  }

  handleData(data) {
    this.setState({
      params: data
      
    });

   // console.log(data);
  }

  loadDetail(logement){

    localStorage.setItem('requetedetailimg', logement.images);
    localStorage.setItem('requetedetail',  JSON.stringify(logement) );
    window.location = "#/logement-detail/"+logement._id;

  }

  handlePageChange(pageNumber) {
  /*  console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});*/
  }



  render()
  {
    return (
      <div>
    {/* <Header/> */}
    
    <MediaQuery minWidth={880}>
      <SearchForm handlerFromParant={this.handleData} />
    </MediaQuery>


      <div className="searchResultsContainer">
   
      <div className="resultContainer">
     
    {(this.state.respons===null) ? <em>Loading...</em> :this.state.respons.map((logement_)=>
    
        <div className="searchLinkContainer" href="#" >
          
        <div className="carResultContainer">
       
     
            <OwlCarousel
            className="owl-theme"
            loop
            items={1}
            margin={10}
            dots={false}
            nav
            navText = {[
              "<i class='icon-chevron-left icon-white'><</i>",
              "<i class='icon-chevron-right icon-white'>></i>"
              ]}
          >
         

          {logement_.images.map((imge)=> 
               <div className="item" onClick={() => this.loadDetail(logement_)}><img  src={"https://res.cloudinary.com/lepiston/image/fetch/h_270/http://lakmoo.com/images/"+imge}/></div>
           )}

          </OwlCarousel>
          <div className={logement_.status==1?"statusdisp":"statusnon"}>
          <Icon icon={time} />
            
         {logement_.status==1?"Disponible":"A vérifier"}
           
          </div>
          <div className="carDescriptionBoxes">
                  <div className="carDescription">
                  <div className="carInformation">
                  <div className="iconSearchResults" > <Icon icon={home} /> </div>
                  <div className="iconDescription">
                  {logement_.type} 
                  </div>
                  </div>
                  <div className="carInformation">
                  <div className="iconSearchResults" > <Icon icon={building} /> </div>
                  <div className="iconDescription"> 
                  {logement_.etat}
                  </div>
                  </div>
                  <div className="carInformation">
                  <div className="iconSearchResults" > <Icon icon={bed} /> </div>
                  <div className="iconDescription">
                  {logement_.chambre} chambre(s)
                  </div>
                  </div>
                  <div className="carInformation">
                  <div className="iconSearchResults" > <Icon icon={shower} /> </div>
                  <div className="iconDescription">
                  {logement_.douche} douche(s)
                  </div>
                  </div>
                  </div>
                  <div className="carPriceBox">
                  <div className="carPriceDescription">
                  <div className="carPrice" id="PriceSearchResults">
                  {logement_.ville}
                  </div>
                  </div>

                  <div className="carPriceDescription">
                  <div className="carPrice" id="PriceSearchResults">
                  {logement_.loyer}
                  </div>
                  </div>
                  <div className="carPriceDescription">
                  <div className="paymentSpreadResults">
                 Avance: {logement_.avance_loyer} Mois
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  

    )}

    

</div>
</div>

                <Pagination 
                  activePage={this.state.activePage} 
                  itemsCountPerPage={50} 
                  totalItemsCount={450} 
                  pageRangeDisplayed={5}
                  onChange={this.handlePageChange}
                />
 
              <Footer/>
              <DistanceDiv/>
              <MediaQuery maxWidth={880}>
               <ContactUsprintBar/>
              </MediaQuery>
              </div>

    )
  }
}

export default ResultSearch;