import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./../../css/searchcss.css"
import Pagination from "react-js-pagination";
import Header from './header'
import Footer from './footer'
import { ButtonToolbar, Button, Form, FormGroup, FormCheck, ControlLabel, FormControl, Row, Col } from 'react-bootstrap';
import ContactUsprintBarDetail from './ContactUsprintBardetail'
import ContactUsprintBar from './ContactUsprintBar'
import config from '../../config/config'
import ressouces from '../../ressources/ressouces'
import DistanceDiv from './DistanceDiv'
import SearchForm from './SearchForm'
import axios from "axios";
import MediaQuery from 'react-responsive';
import Modal from 'react-responsive-modal';
import { Icon } from 'react-icons-kit'
import { chevronsRight } from 'react-icons-kit/feather'
import { Link, animateScroll as scroll } from "react-scroll";
import ImgMediaCardBendo from '../components/ImgMediaCard';




const options = {
  items: 4,
}
class ResultSearchDetail extends React.Component {


  constructor(props) {
    super();
    this.state = {
      productName: null,
      respons: null,
      open: false,
      visitesimple: false,
      images: null,
      params: null,
      timeToScrool: null,
    }

    this.gotoLink = this.gotoLink.bind(this);
    this.loaContenue = this.loaContenue.bind(this);
  }

  async getData() {
    const { match: { params } } = this.props;
    console.log(params.name)
    this.setState({ productName: params.name })



  }


  componentDidMount() {
    //var url = config.SERVER_ADRESSE + 'trouver-un-logement/liste-de-ma-recherche?region=Centre&type=Studio';

    this.getData()

    this.scrollToBottom()
  }



  componentWillMount() {


    this.scrollToBottom()
  }


  scrollToBottom = (x) => {

    scroll.scrollTo(x);
  };


  gotoLink() {

    return <Link
      activeClass="active"
      to="carDescriptionBoxes"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
    >
    </Link>
  }


  loaContenue() {

    return <div >

      <div className="homeActivitiesContainer">


        <div className="titre_activite_div">
          <h1 className="titre_activite">{ressouces.ACTIVITY.R_D.title}</h1>
        </div>




        <MediaQuery minWidth={1034}>


          <div className="rd_generalites">


            <div className="carousel_activite_detail" >

              {ressouces.ACTIVITY.R_D.description}


            </div>

            <div className="item-activity" ><img src={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.img1} />

            </div>

          </div>
        </MediaQuery>


        <MediaQuery maxWidth={1034}>


          <div className="rd_generalites">


            <div className="item-activity" ><img src={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.img1} />

            </div>

            <div className="carousel_activite_detail" >

              {ressouces.ACTIVITY.R_D.description}


            </div>


          </div>

        </MediaQuery>


        <div className="activity_separation"></div>


        <div className="titre_activite_div">
          <h1 className="titre_activite">{ressouces.ACTIVITY.R_D.case1.title}</h1>
        </div>




        <div className="rd_generalites">

          <div className="item-activity" >
            <img src={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case1.img1} />

          </div>


          <div className="carousel_activite_detail" >

            {ressouces.ACTIVITY.R_D.case1.description}


          </div>


        </div>



        <div className="activity_separation"></div>
        <div className="titre_activite_div">
          <h1 className="titre_activite">{ressouces.ACTIVITY.R_D.case2.title}</h1>
        </div>


        <MediaQuery minWidth={1034}>


          <div className="rd_generalites">


            <div className="carousel_activite_detail" >



              {ressouces.ACTIVITY.R_D.case2.description}

              <br /><br />
              {ressouces.ACTIVITY.R_D.case2.description2}


            </div>


            <div className="item-activity" >
              <img src={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case2.img1} />

            </div>

          </div>

        </MediaQuery>


        <MediaQuery maxWidth={1034}>

          <div className="rd_generalites">




            <div className="item-activity" >
              <img src={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case2.img1} />

            </div>


            <div className="carousel_activite_detail" >



              {ressouces.ACTIVITY.R_D.case2.description}

              <br /><br />
              {ressouces.ACTIVITY.R_D.case2.description2}


            </div>



          </div>
        </MediaQuery>









        <div className="activity_separation"></div>


        <div className="titre_activite_div">
          <h1 className="titre_activite">{ressouces.ACTIVITY.R_D.case3.title}</h1>
        </div>


        <div className="rd_generalites">

          <div className="item-activity" >
            <img src={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case3.img1} />

          </div>


          <div className="carousel_activite_detail" >



            {ressouces.ACTIVITY.R_D.case3.description}
            <br /><br />

            {ressouces.ACTIVITY.R_D.case3.description2}



          </div>


        </div>









        <div className="activity_separation"></div>
        <div className="titre_activite_div">
          <h1 className="titre_activite">{ressouces.ACTIVITY.R_D.case4.title}</h1>
        </div>

        <MediaQuery minWidth={1034}>


          <div className="rd_generalites">


            <div className="carousel_activite_detail" >



              {ressouces.ACTIVITY.R_D.case4.description}

              <br /><br />



            </div>


            <div className="item-activity" >
              <img src={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case4.img1} />

            </div>

          </div>

        </MediaQuery>


        <MediaQuery maxWidth={1034}>

          <div className="rd_generalites">




            <div className="item-activity" >
              <img src={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case4.img1} />

            </div>


            <div className="carousel_activite_detail" >



              {ressouces.ACTIVITY.R_D.case4.description}

              <br /><br />


            </div>



          </div>
        </MediaQuery>

















        <div>

        </div>

      </div>
    </div>
  }

  render() {

    const { open } = this.state;
    console.log(this.props.match.url);
    return (
      // <div className="page_rd_bendo"> 
      <div className="row j"> 
        <div className="row r_d x">
        <div className="col-xs-12 col-sm-6 col-md-4 k">
            <ImgMediaCardBendo title={ressouces.ACTIVITY.R_D.case2.title} content={ressouces.ACTIVITY.R_D.case2.description} image={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case2.img1} lien={this.props.match.url+"/prothese"}/>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 k">
            <ImgMediaCardBendo title={ressouces.ACTIVITY.R_D.case1.title} content={ressouces.ACTIVITY.R_D.case1.description} image={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case1.img1}lien={this.props.match.url+"/app_dep_surd"}/>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 k">
            <ImgMediaCardBendo title={ressouces.ACTIVITY.R_D.case3.title} content={ressouces.ACTIVITY.R_D.case3.description} image={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case3.img1} lien={this.props.match.url+"/app_reg"}/> 
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 k">
            {/* <ImgMediaCardBendo title={ressouces.ACTIVITY.R_D.case4.title} content={ressouces.ACTIVITY.R_D.case4.description} image={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.case4.img1}lien={this.props.match.url+"/systeme_embarque"}/>  */}
             <ImgMediaCardBendo title={ressouces.ACTIVITY.R_D.case4.title} content={ressouces.ACTIVITY.R_D.case4.description} image={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.img1}lien={this.props.match.url+"/systeme_embarque"}/>
          </div>
           {/* <div className="col-xs-12 col-sm-6 col-md-4 k">
            <ImgMediaCardBendo title={ressouces.ACTIVITY.R_D.case1.title} content={ressouces.ACTIVITY.R_D.description} image={config.CLOUDIMGS_ACTIVITY + ressouces.ACTIVITY.R_D.img1} lien={this.props.match.url}/>
          </div> */} 
        </div>
        <div className="body_campagne"></div>
        {/* {this.state.productName!=null?this.loaContenue():<div className="loading_gif"><img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" /></div>} */}
        {/*  <ul class="list-group">
        <li class="list-group-item" >
           <span class="pull-left ">
             <img src={config.CLOUDIMGS_ACTIVITY+ressouces.ACTIVITY.R_D.case4.img1}  class="img-reponsive img-rounded" />
           </span>
           Text
        </li>
    </ul> */}
        {/* <div className="row">
          <Footer />
        </div> */}
      </div>

    )
  }
}

export default ResultSearchDetail;