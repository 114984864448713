import React, { Component } from "react";
import Header from './header'
import Map from './map'
import Footer from './footer'
import "./../../css/cssheader2.css"




class Entry extends Component {
  
  constructor(props) {

    super(props)
    

  }
 

  render() {
    
    return (

      <div>
        

       {/* <Header/> */}
     
       <Map/>
       
       {/* <Footer/> */}

     </div>
    
    );
  }  
}

export default Entry;
