import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./../../css/searchcss.css"
import Pagination from "react-js-pagination";
import Header from './header'
import Footer from './footer'
import {ButtonToolbar, Button,Form, FormGroup,FormCheck, ControlLabel, FormControl,Row,Col} from 'react-bootstrap';
import ContactUsprintBarDetail from './ContactUsprintBardetail'
import ContactUsprintBar from './ContactUsprintBar'
import config from '../../config/config'
import ressouces from '../../ressources/ressouces'

import DistanceDiv from './DistanceDiv'
import SearchForm from './SearchForm'
import axios from "axios";
import MediaQuery from 'react-responsive';
import Modal from 'react-responsive-modal';
import { Icon } from 'react-icons-kit'

import { chevronsRight } from 'react-icons-kit/feather'
import { Link, animateScroll as scroll } from "react-scroll";




const options = {
    items: 4,
}
class ResultSearchDetail extends React.Component {


  constructor(props) {
    super();
    this.state = {
      productName:null,
      respons: null,
      open: false,
      visitesimple: false,
      images: null,
      params:null,
      timeToScrool:null,
    }
    
    this.handleData = this.handleData.bind(this);
    this.gotoLink = this.gotoLink.bind(this);
    this.loaContenue = this.loaContenue.bind(this);
  }

  async getData() {
    const { match: { params } } = this.props;
   
    this.setState({productName: params.name })
   
    

}
  
 
componentDidMount() {
  //var url = config.SERVER_ADRESSE + 'trouver-un-logement/liste-de-ma-recherche?region=Centre&type=Studio';
  
   this.getData()

   this.scrollToBottom()
}
 
  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentWillMount(){

    
    this.scrollToBottom()
  }

  handleData(data) {
    this.setState({
      params: data
      
    });

   // console.log(data);
  }



  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
  }

  scrollToBottom = (x) => {

    scroll.scrollTo(x);
};


  gotoLink(){

    return  <Link
          activeClass="active"
          to="carDescriptionBoxes"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
      >
      </Link>
  }


  loaContenue(){

    return  <div className="detail_produit">
    <div className="detail_bloc1">
    <div className="searchResultsContainerDetail">
          <div className="homeResultContainer">

        <div className="produits_separation1"></div> 
{this.state.productName=="PEA_OEA"?this.scrollToBottom(100):""}
          <div className="item" ><img src={config.CLOUDIMGS_PRODUCTS+ressouces.PRODUCTS.PEA_OEA.images.img1}/>
            <div className="titre_produit_div">
              <h1 className="titre_produit">{ressouces.PRODUCTS.PEA_OEA.title}</h1>
            </div>
                 
            <div className="carousel_produit_detail" >
                <div className="popin-trigger">
                    <h1 className="description_produit">{ressouces.PRODUCTS.PEA_OEA.description}</h1>
                                
                </div>
            </div>
          </div>

        

          <div className="produits_separation"></div> 

        {this.state.productName=="OEA"?this.scrollToBottom(500):""}

          <div className="item" ><img src={config.CLOUDIMGS_PRODUCTS+ressouces.PRODUCTS.OEA.images.img1} className="photo"/> 
            <div className="titre_produit_div">
                <h1 className="titre_produit">{ressouces.PRODUCTS.OEA.title}</h1>
            </div>

            <div className="carousel_produit_detail" >
                <div className="popin-trigger">
                    <h1 className="description_produit">{ressouces.PRODUCTS.OEA.description}</h1>
                                              
                </div>
            </div>
          </div>



          <div className="produits_separation"></div> 

          {this.state.productName=="PROTHESE"?this.scrollToBottom(1400):""}
                              
            <div className="item" ><img src={config.CLOUDIMGS_PRODUCTS+ressouces.PRODUCTS.PROTHESE.images.img1} className="photo"/>
              <div className="titre_produit_div">
                  <h1 className="titre_produit">{ressouces.PRODUCTS.PROTHESE.title}</h1>
              </div>
                            
              <div className="carousel_produit_detail" >
                  <div className="popin-trigger">
                      <h1 className="description_produit">{ressouces.PRODUCTS.PROTHESE.description}</h1>
                                    
                  </div>
              </div>
            </div>



       
       </div>
   
           </div>
    





      <div>

    </div>

    </div>       
    </div>
  }

  render()
  {

    const { open } = this.state;

    return (
      <div >
    
    {/* <Header/> */}
   
          {this.state.productName!=null?this.loaContenue():<img src="https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif" />}
              {/* <Footer/> */}
            
              
    
          </div>

    )
  }
}

export default ResultSearchDetail;