import React from "react";


const ContactUsprintBar = () => (
    <div className="_2gSS5" data-qa-id="navbar_container">
    <div className="yWFfm">
      <div data-qa-id="adview_button_phone_navbar" data-pub-id="clicknumero">
        <div>
          <div className="_1tLT2"><div className="_2_Cd_">
            <div className="_2LnD0">
              <span className="_1vK7W" name="phone">
                <svg viewBox="0 0 24 24" data-name="Calque 1" focusable="false">
                  
                </svg>
              </span>
            </div>Chercher</div>
          </div>
        </div>
      </div>
      </div>
      <div className="yWFfm">
      <div data-qa-id="adview_button_contact_navbar" className="trackable">
      <div className="contactButtonRef">
      <div className="_3yLbL">
      <a><div className="_2_Cd_">
      <div className="_2LnD0">
      <span className="_1vK7W" name="message">
        
        <svg viewBox="0 0 24 24" data-name="Calque 1" focusable="false">
                  <path d="M21.65 16.37l-3.4-.37a2.69 2.69 0 0 0-2.19.76l-2.45 2.44a20.11 20.11 0 0 1-8.81-8.81l2.47-2.47A2.61 2.61 0 0 0 8 5.73l-.35-3.37A2.68 2.68 0 0 0 5 0H2.68A2.66 2.66 0 0 0 0 2.77 22.71 22.71 0 0 0 21.23 24 2.66 2.66 0 0 0 24 21.32V19a2.64 2.64 0 0 0-2.35-2.63z">
                  </path>
                </svg>
      </span>
      </div> 690 26 07 70</div>
      </a></div></div></div></div>
      <div className="yWFfm"><div data-qa-id="adview_button_soyouz_navbar" className="trackable">
      <div><a href="/vacances/vacancier/calendrier/1569108173/"><div className="_2_Cd_">
      <div className="_2LnD0">
      <span className="_1vK7W" name="calendarvalid">
      <svg viewBox="0 0 24 24" data-name="Calque 1" focusable="false">
            <path d="M21.6 0H2.4A2.41 2.41 0 0 0 0 2.4v14.4a2.41 2.41 0 0 0 2.4 2.4h16.8L24 24V2.4A2.41 2.41 0 0 0 21.6 0zM18 14.4h-7.2a1.29 1.29 0 0 1-1.2-1.2 1.29 1.29 0 0 1 1.2-1.2H18a1.29 1.29 0 0 1 1.2 1.2 1.29 1.29 0 0 1-1.2 1.2zm0-3.6H6a1.29 1.29 0 0 1-1.2-1.2A1.29 1.29 0 0 1 6 8.4h12a1.29 1.29 0 0 1 1.2 1.2 1.29 1.29 0 0 1-1.2 1.2zm0-3.6H6A1.29 1.29 0 0 1 4.8 6 1.29 1.29 0 0 1 6 4.8h12A1.29 1.29 0 0 1 19.2 6 1.29 1.29 0 0 1 18 7.2z">
            </path>
        </svg>
      </span>
      </div><div className="_32GDD">Ecrivez nous</div></div></a></div></div></div>
  </div>
);

export default ContactUsprintBar;