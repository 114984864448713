import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./../../css/searchcss.css"
import Pagination from "react-js-pagination";
import Header from './header'
import Footer from './footer'

import config from '../../config/config'
import ressouces from '../../ressources/ressouces'

import MediaQuery from 'react-responsive';

import { Link, animateScroll as scroll } from "react-scroll";
import { Icon } from 'react-icons-kit'
import { entypo } from 'react-icons-kit/feather'
import { check } from 'react-icons-kit/feather';



const options = {
    items: 4,
}
class Apropos extends React.Component {


  constructor(props) {
    super();
    this.state = {
      productName:null,
      respons: null,
      open: false,
      visitesimple: false,
      images: null,
      params:null,
      timeToScrool:null,
    }
    
    this.gotoLink = this.gotoLink.bind(this);
    this.loaContenue = this.loaContenue.bind(this);
  }


  
 
componentDidMount() {
  //var url = config.SERVER_ADRESSE + 'trouver-un-logement/liste-de-ma-recherche?region=Centre&type=Studio';

   this.scrollToBottom()
}
 


  componentWillMount(){

    
    this.scrollToBottom()
  }


  scrollToBottom = (x) => {

    scroll.scrollTo(x);
};


  gotoLink(){

    return  <Link
          activeClass="active"
          to="carDescriptionBoxes"
          spy={true}
          smooth={true}
          offset={-70}
          duration= {500}
      >
      </Link>
  }


  loaContenue(){

    return   <div >
   
          <div className="homeActivitiesContainer">


                <div className="titre_activite_div_c">
                <h1 className="titre_activite">A propos de BENDO</h1>
                </div>  


               


            <MediaQuery minWidth={1034}>


            <div className="rd_generalites_c1">
              
                        
              <div className="carousel_activite_detail_apropos1" >
                
                        <p>Pionnier en Afrique Centrale, Bendo Médical développe depuis quatre ans, les appareils de dépistage, de diagnostic de la surdité et les prothèses auditives. Ces appareils intègrent les technologies innovantes et sont adaptés au besoin des utilisateurs.

              Bendo est constituée d’une équipe d’ingénieurs, de chercheurs, de médecins … <br/><br/> Elle partage des liens étroits avec les universités et les partenaires de par le monde.</p>              
                        
              </div>

              
              <div  className="item-apropos">
                  <img src="https://res.cloudinary.com/lepiston/image/fetch/h_270/https://res.cloudinary.com/serpoma/image/upload/v1594217711/IMG_4164.jpg"/>

                  </div>

              </div> 
            </MediaQuery>


            <MediaQuery maxWidth={1034}>


            <div className="rd_generalites_c1">
              
            <div  className="item-apropos">
                  <img src="https://res.cloudinary.com/lepiston/image/fetch/h_270/https://res.cloudinary.com/serpoma/image/upload/v1594217711/IMG_4164.jpg"/>

                  </div>


              <div className="carousel_activite_detail_apropos1" >
                
                        <p>Pionnier en Afrique Centrale, Bendo Médical développe depuis quatre ans, les appareils de dépistage, de diagnostic de la surdité et les prothèses auditives. Ces appareils intègrent les technologies innovantes et sont adaptés au besoin des utilisateurs.

              Bendo est constituée d’une équipe d’ingénieurs, de chercheurs, de médecins … <br/><br/> Elle partage des liens étroits avec les universités et les partenaires de par le monde.</p>              
                        
              </div>

              
              

              </div> 
            </MediaQuery>





    <div className="activity_separation"></div>

                <div className="titre_activite_div">
                <h1 className="titre_activite">Un Son en OR</h1>
                </div>  




                <div className="rd_generalites">
        
                        
                    <div className="carousel_activite_detail_apropos" >
                      
                             Le son est fondamental pour tout être humain. Il permet d’accéder à un monde radieux. L’écoute et le langage parlé garantissent une communication fluide. Ils nous permettent de comprendre, reconnaître et communiquer avec les autres.
                             
                             <br/>Bendo met son expertise et sa dévotion dans la recherche des solutions aux problèmes de surdité. Elle veut donner à tous la chance d’ecouter le bruit du vent, le chant des oiseaux…
                                                
                              
                    </div>


                </div> 



     <div className="activity_separation"></div>

                <div className="titre_activite_div">
                <h1 className="titre_activite">Notre engagement</h1>
                </div>  

                                           
                          <div className="carousel_activite_detail_apropos" >
                      
                          Nous nous engageons à lutter contre la surdité en encourageant le dépistage précoce et systématique des nouveaux nés, en proposant des prothèses auditives de haute performance pour les malentendants.                  
                                    
                         
                          <br/> <br/>Pour parfaire nos engagements, nous prenons appui sur quatre piliers :
                                
                      </div>

            <div >
                      <div className="engagement_div" >
                      <Icon icon={check} />
                      Répondre au besoin de l'utilisateur

                     </div>  


                        <div className="engagement_description"> L'utilisateur est au centre des préoccupations de l’entreprise. Elle s’attèle à répondre aux exigences de celui-ci en lui offrant des produits au-dessus de ses attentes.</div>


                     <div className="engagement_div" >
                     <Icon icon={check} /> 
                     Recherche et développement 

                     </div>     

                     <div className="engagement_description"> Bendo partage des liens étroits avec des chercheurs et experts dans le monde pour une amélioration continue de ses appareils. </div>


                     <div className="engagement_div" >
                     <Icon icon={check} />
                      technologies innovantes

                     </div>     

                     <div className="engagement_description"> Bendo est à la pointe des nouvelles technologies. Grâce à son équipe dynamique, compétente et ambitieuse, elle améliore continuellement la qualité de ses appareils.</div>

                     <div className="engagement_div" >
                     <Icon icon={check} />
                      Services

                     </div>             

                     <div className="engagement_description"> Bendo vous accompagne dans la formation à l'utilisation de ses appareils et assure de façon systématique leur maintenance.</div>

         </div>
         <div className="activity_separation"></div>

<div className="titre_activite_div">
<h1 className="titre_activite">Notre vision</h1>
</div> 
<div className="carousel_activite_detail_apropos" >
Notre Vision est d’offrir à tous une chance d’accéder à des soins de qualité grâce à des technologies innovantes et adaptées au contexte socio-économique des pays d’Afrique Subsaharienne. Pour y parvenir, un accent est porté sur la recherche-développement au sein de la Start up Camerounaise BENDO ayant à son actif plusieurs brevets d’inventions. A ce jour, les axes de R&D au sein de la Start up portent sur les appareils de dépistage de la surdité néonatale, les appareils de réhabilitation de la surdité, les appareils de régulation de l’énergie électrique et les systèmes embarqués.                                                            
                  </div>
                  <br/> <br/>
                        <div className="item-activity" >
                         
                      </div>



             

                   

     
      



      <div>

    </div>

    </div>       
    </div>
  }

  render()
  {

    const { open } = this.state;

    return (
      <div>
    
    {/* <Header/> */}
      {this.loaContenue()}
              {/* <Footer/> */}
            
              
    
          </div>

    )
  }
}

export default Apropos;