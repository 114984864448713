module.exports = {
   SERVER_ADRESSE:"http://localhost:4000/",
    //SERVER_ADRESSE:"http:\/\/192.34.63.63:4000/",
   //SERVER_ADRESSE:"https:\/\/lakmoo.com:4000/",
    CURRENT_IMG_PATH:"/home/bendodi/bendo/SI/bendoSite/frontclient/public/images/",
    CURRENT_IMG_FILES:"/home/bendodi/bendo/SI/bendoSite/frontclient/public/files/",
    CURRENT_CONTRAT: "/contrat.pdf",

    CLOUDIMGS_PRODUCTS:"https://res.cloudinary.com/lepiston/image/fetch/h_700/",
    CLOUDIMGS_ACTIVITY:"https://res.cloudinary.com/lepiston/image/fetch/h_270/",

}